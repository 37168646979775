import {Component, Input, OnChanges} from '@angular/core';
import {CategoryResponse} from "../../../shared/models/category.response";
import {CategoryFlatResponse} from "../../../shared/models/category-flat.response";
import {BreadcrumbModule} from "primeng/breadcrumb";

@Component({
  selector: 'bop-market-breadcrumb',
  standalone: true,
  imports: [
    BreadcrumbModule
  ],
  templateUrl: './market-breadcrumb.component.html',
  styleUrl: './market-breadcrumb.component.scss'
})
export class MarketBreadcrumbComponent implements OnChanges {
  @Input() public category: CategoryResponse | undefined;
  public breadCrumbItems: {label: string}[] = [];
  public baseItem: {label: string} = {label: 'Продукти'};

  ngOnChanges() {
    if (this.category) {
      this.breadCrumbItems = [];
      this.populateBreadCrumbItems(this.category);
    } else {
      this.breadCrumbItems.unshift(this.baseItem)
    }
  }

  populateBreadCrumbItems(category: CategoryResponse | CategoryFlatResponse) {
    this.breadCrumbItems.unshift({label: category?.name});

    if (category.parent) {
      this.populateBreadCrumbItems(category.parent);
    } else  {
      this.breadCrumbItems.unshift(this.baseItem);
    }
  }
}
