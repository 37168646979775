import {DonationsState} from "./donations.state";
import {createFeature, createReducer, on} from "@ngrx/store";
import {
  createDonation, createDonationError,
  createDonationSuccess,
  inviteTrustee,
  inviteTrusteeError,
  inviteTrusteeSuccess
} from "./donations.actions";

const initialState: DonationsState = {
  pendingInvitationLoading: false,
  pendingDonationLoading: false
}

const profileFeature = createFeature({
  name: "Donation",

  reducer: createReducer(
    initialState,

    on(inviteTrustee, (state, {}) => ({
      ...state,
      pendingInvitationLoading: true
    })),

    on(inviteTrusteeSuccess, (state, {}) => ({
      ...state,
      pendingInvitationLoading: false
    })),

    on(inviteTrusteeError, (state, {}) => ({
      ...state,
      pendingInvitationLoading: false
    })),

    on(createDonation, (state, {}) => ({
      ...state,
      pendingDonationLoading: true
    })),

    on(createDonationSuccess, (state, {}) => ({
      ...state,
      pendingDonationLoading: false
    })),

    on(createDonationError, (state, {}) => ({
      ...state,
      pendingDonationLoading: false
    })),
  )
})


export const {
  name: donationFeatureKey,
  reducer: donationReducer,
  selectPendingInvitationLoading,
  selectPendingDonationLoading
} = profileFeature;

