
//
import {createAction, props} from "@ngrx/store";
import {CategoryResponse} from "../../../shared/models/category.response";
import {MeasurementResponse} from "../../../shared/models/measurement.response";
import {CategoryRequest} from "../../../shared/models/category.request";
import {TagStrippedResponse} from "../../../shared/models/tag-stripped.response";
import {MenuItem} from "primeng/api";

export const getAllCategories = createAction(
  "[Market_Categories_Get] Get all existing categories",
  props
)

export const getAllCategoriesSuccess = createAction(
  "[Market_Categories_Get_Success] Get all existing categories success",
  props<{ categories: CategoryResponse[] }>()
)

export const getCurrentSearchCategoryDetails = createAction(
  "[Market_Categories_Current] Get current selected category details",
  props<{ categoryId: number}>()
)

export const getCurrentSearchCategoryDetailsSuccess = createAction(
  "[Market_Categories_Current_Success] Get current selected category details success",
  props<{ category: CategoryResponse }>()
)

export const loadSelectableCategoriesMenuItems = createAction(
  "[Market_Menu_Categories_Selectable] Load all selectable menu items",
  props
)

export const setCurrentSearchWord = createAction(
  "[Market_Menu_CurrentSearchWorld] Set new search word",
  props<{ searchWord: string }>()
)

export const loadSelectableCategoriesMenuItemsSuccess = createAction(
  "[Market_Menu_Categories_Selectable_Success] Load all selectable menu items success",
  props<{ menuItems: MenuItem[] }>()
)

export const loadNestedCategoriesMenuItems = createAction(
  "[Market_Menu_Categories_Nested] Load all nested menu items",
  props
)

export const loadNestedCategoriesMenuItemsSuccess = createAction(
  "[Market_Menu_Categories_Nested_Success] Load all nested menu items success",
  props<{ menuItems: MenuItem[] }>()
)


export const selectParentCategoryForProductCreation = createAction(
  "[Market_Categories_Parents_Select] Select category from create page sidebar",
  props<{ categoryId: number }>()
)

export const unselectParentCategoryForProductCreation = createAction(
  "[Market_Categories_Parents_Unselect] Unselect category from create page sidebar",
  props<{ categoryId: number }>()
)

export const selectSubcategoryForProductCreation = createAction(
  "[Market_Subcategories_Select] Select subcategory from create page ",
  props<{ category: CategoryResponse[] }>()
)

export const unselectSubcategoryForProductCreation = createAction(
  "[Market_Subcategories_Unselect] Unselect subcategory from create page ",
  props<{ categoryId: number }>()
)

export const selectProductTypeForProductCreation = createAction(
  "[Market_ProductType_Select] Select product type from create page",
  props<{ category: CategoryResponse[] }>()
)

export const unselectProductTypeForProductCreation = createAction(
  "[Market_ProductType_Unselect] Unselect product type from create page",
  props<{ categoryId: number }>()
)

export const selectTagsForProductCreation = createAction(
  "[Market_Tags_Select] Select tag from create page",
  props<{ tag: TagStrippedResponse }>()
)

export const unselectTagsForProductCreation = createAction(
  "[Market_Tags_Unselect] Unselect tag from create page",
  props<{ tagId: number }>()
)

export const getAllMeasurements = createAction(
  "[Market_Measurements_Get] Get all existing measurements",
  props
)

export const getAllMeasurementsSuccess = createAction(
  "[Market_Measurements_Get_Success] Get all existing measurements success",
  props<{ measurements: MeasurementResponse[] }>()
)
//
// export const updateMyProfile = createAction(
//   "[Profile_Update] Update logged in user's profile",
//   props<{ request: ProfileRequest }>()
// )
//
//
// export const updateMyProfileSuccess = createAction(
//   "[Profile_Update_Success] Update logged in user's profile success",
//   props<{ response: ProfileResponse }>()
// )
//
// export const updateMyProfileError = createAction(
//   "[Profile_Update_Error] Update logged in user's profile error",
//   props<{ error: any }>()
// )
//
// export const getInvitedProfile = createAction(
//   "[Profile_Get_Invited] Get invited user's profile",
//   props<{ token: string }>()
// )
//
// export const getInvitedProfileSuccess = createAction(
//   "[Profile_Get_Invited_Success] Get invited user's profile success",
//   props<{ response: ProfileResponse }>()
// )
//
// export const getInvitedProfileError = createAction(
//   "[Profile_Get_Invited_Error] Get invited user's profile error",
//   props<{ error: any }>()
// )
//
// export const finishInvitedProfile = createAction(
//   "[Profile_Finish_Invited] Finish invited user's profile",
//   props<{ request: ProfileRequest, token: string }>()
// )
//
// export const finishInvitedProfileSuccess = createAction(
//   "[Profile_Finish_Invited_Success] Finish invited user's profile success",
//   props<{ response: ProfileResponse }>()
// )
//
// export const finishInvitedProfileError = createAction(
//   "[Profile_Finish_Invited_Error] Get invited user's profile error",
//   props<{ error: any }>()
// )
//
//
// export const setSelectedRole = createAction(
//   "[Profile_Edit_SelectRoles_Add] Set currently selected role",
//   props<{ role: Role }>()
// )
//
// export const removeSelectedRole = createAction(
//   "[Profile_Edit_SelectRoles_Remove] Remove currently selected role",
//   props<{ role: Role }>()
// )
//
// export const setSelectedAddress = createAction(
//   "[Profile_Edit_SelectAddress_Add] Set currently selected address",
//   props<{ address: LocationResponse }>()
// )
//
// export const removeSelectedAddress = createAction(
//   "[Profile_Edit_SelectAddress_Remove] Remove currently selected address",
//   props<{ address: LocationResponse }>()
// )
//
// export const setSelectedWishes = createAction(
//   "[Profile_Edit_SelectWishes] Set currently selected wishes",
//   props<{ wishes: WishPackage }>()
// )
//
// export const setSelectedWishesWantToBuy = createAction(
//   "[Profile_Edit_SelectWishes_WantToBuy] Set currently selected wishes want to buy",
//   props<{ wishes: WishDto[] }>()
// )
//
// export const setSelectedWishesWantToSell = createAction(
//   "[Profile_Edit_SelectWishes_WantToSell] Set currently selected wishes want to sell",
//   props<{ wishes: WishDto[] }>()
// )
//
// export const setSelectedWishesWantToExchange = createAction(
//   "[Profile_Edit_SelectWishes_WantToExchange] Set currently selected wishes want to exchange",
//   props<{ wishes: WishDto[] }>()
// )
//
// export const setSelectedWishesBopSoftware = createAction(
//   "[Profile_Edit_SelectWishes_BopSoftware] Set currently selected wishes bop software",
//   props<{ wishes: WishDto[] }>()
// )
//
// export const getTrusteeTransfer = createAction(
//   "[Get_Trustee_Transfer] Get ongoing trustee transfer"
// )
//
// export const getTrusteeTransferSuccess = createAction(
//   "[Profile_Get_Trustee_Transfer_Success] Get ongoing trustee transfer success",
//   props<{ response: TrusteeTransferResponse }>()
// );
//
// export const getTrusteeTransferError = createAction(
//   "[Profile_Get_Trustee_Transfer_Error] Get ongoing trustee transfer error",
//   props<{ error: any }>()
// );
