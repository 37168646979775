<div class="flex flex-column gap-3 mb-3">
  @for (parent of possibleParents; track parent) {
    <div>
      <label>
        <p-radioButton
          inputId="subcategory_parent"
          name="subcategory_parent"
          [value]="parent.id"
          [(ngModel)]="selectedParent"/>
        <span class="ml-2">{{ parent.name }}</span>
      </label>
    </div>
  }
</div>

<bop-accept-reject-buttons (onReject)="reject()" (onAccept)="accept()"></bop-accept-reject-buttons>
