import {Component, EventEmitter, Inject, inject, OnInit, Output} from '@angular/core';
import {selectAllCategories} from "../../../private/market/store/market.reducer";
import {Store} from "@ngrx/store";
import {CategoryResponse} from "../../models/category.response";
import {MultiSelectChangeEvent, MultiSelectModule} from "primeng/multiselect";
import {TagStrippedResponse} from "../../models/tag-stripped.response";
import {FormRowWrapperComponent} from "../form-wrapper/form-row-wrapper/form-row-wrapper.component";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {ReactiveFormsModule} from "@angular/forms";
import {combineLatest, map} from "rxjs";
import {SubcategorySelectionService} from "../../services/creatable-dropdown-service/subcategory-selection.service";
import {
  CreatableDropdownMultipleComponent
} from "../creatable-dropdown/creatable-dropdown-multiple/creatable-dropdown-multiple.component";
import {NamedElement} from "../../models/named-element";
import {ProductTypeSelectionService} from "../../services/creatable-dropdown-service/product-type-selection.service";
import {
  selectProductTypeForProductCreation,
  selectSubcategoryForProductCreation
} from "../../../private/market/store/market.actions";
import {TagSelectionService} from "../../services/creatable-dropdown-service/tag-selection.service";
import {CreatableDropdownOutput} from "../creatable-dropdown/creatable-dropdown.output";

@Component({
  selector: 'bop-select-categories-tags',
  standalone: true,
  imports: [
    FormRowWrapperComponent,
    InputGroupAddonModule,
    InputGroupModule,
    MultiSelectModule,
    ReactiveFormsModule,
    CreatableDropdownMultipleComponent
  ],
  templateUrl: './select-categories-tags.component.html',
  styleUrl: './select-categories-tags.component.scss'
})
export class SelectCategoriesTagsComponent implements OnInit {
  @Output() onTagSelection: EventEmitter<number[]> = new EventEmitter();
  @Output() onCategoryTypeSelection: EventEmitter<number[]> = new EventEmitter();
  @Output() onProductTypeSelection: EventEmitter<number[]> = new EventEmitter();

  public categorySelectionService = inject(SubcategorySelectionService);
  public productTypeSelectionService = inject(ProductTypeSelectionService);
  public tagSelectionService = inject(TagSelectionService);

  tags: TagStrippedResponse[] = [];
  subcategories: CategoryResponse[] = [];
  productTypes: CategoryResponse[] = [];
  store = inject(Store);
  $allCategories =  this.store.select(selectAllCategories);
  // $selectedParentCategories = this.store.select(selectNewProductParentCategoriesIds);

  ngOnInit() {
  }

  loadProductTypes($event: MultiSelectChangeEvent) {
    this.productTypes = $event.value.flatMap((value: CategoryResponse) => value.children);
    this.onCategoryTypeSelection.emit($event.itemValue.id)
  }

  loadProductTags($event: MultiSelectChangeEvent) {
    const uniqueTags = new Map<number, TagStrippedResponse>();

    $event.value.flatMap((value: CategoryResponse) => value.tags).forEach((t: TagStrippedResponse) => {
      if (!uniqueTags.has(t.id)) {
        uniqueTags.set(t.id, t);
      }
    });

    this.onProductTypeSelection.emit($event.itemValue.id);
    this.tags = Array.from(uniqueTags.values());
  }

  selectSubcategory($event: CreatableDropdownOutput) {
    console.log('selectedSubcategory', $event);
    this.store.dispatch(selectSubcategoryForProductCreation({category: $event.multipleOutput as any}));
    this.onCategoryTypeSelection.emit($event.multipleOutput.map(e => e.id));
  }

  selectProductType($event: CreatableDropdownOutput) {
    console.log('selectedProductType', $event);
    this.store.dispatch(selectProductTypeForProductCreation({category: $event.multipleOutput as any}));
    this.onProductTypeSelection.emit($event.multipleOutput.map(e => e.id));
  }

  selectTag($event: CreatableDropdownOutput) {
    console.log('selectedTag', $event);
    this.onTagSelection.emit($event.multipleOutput.map(e => e.id));
  }
}
