import {Injectable} from "@angular/core";
import {FilterRequest} from "./filter.request";
import {FilterOperation} from "./filter.operation";

@Injectable({
  providedIn: 'root'
})
export class Filtration {
  static empty(): InvokableFiltration {
    return new SimpleFiltration([]);
  }

  field(field: string) {
    return new SimpleFiltrationFieldStage(field);
  }
}

export class FiltrationRequestParam {
  constructor(
    private _key: string,
    private _value: string
  ) {
  }

  public get key() {
    return this._key;
  }

  public get value() {
    return this._value;
  }

  public toString(): string {
    return this.key + "=" + this.value;
  }
}

export function filterFromRequest(filterRequests: FilterRequest[]): InvokableFiltration {
  return new SimpleFiltration(filterRequests);
}

export interface InvokableFiltration {
  do(): FiltrationRequestParam;
}

class SimpleFiltration implements InvokableFiltration {

  constructor(
    private filterRequests: FilterRequest[],
    private parent: SimpleFiltration | null = null
  ) {
  }

  do(): FiltrationRequestParam {
    return new FiltrationRequestParam("filter", JSON.stringify(this.getFilterRequests()));
  }

  and(field: string) {
    return new SimpleFiltrationFieldStage(field, this);
  }

  protected getFilterRequests(): FilterRequest[] {
    return [...(this.parent?.getFilterRequests() || []), ...this.filterRequests].filter(x => x.value);
  }

}


class SimpleFiltrationFieldStage {

  constructor(
    private field: string,
    private previous: SimpleFiltration = new SimpleFiltration([])
  ) {
  }

  should(operation: string, value: any) {
    return new SimpleFiltration([new FilterRequest(this.field, value, (FilterOperation as any)[operation] as FilterOperation)], this.previous)
  }

  equals(value: any): SimpleFiltration {
    return new SimpleFiltration([new FilterRequest(this.field, value, FilterOperation.EQ)], this.previous)
  }

  isGreaterThan(value: any): SimpleFiltration {
    return new SimpleFiltration([new FilterRequest(this.field, value, FilterOperation.GT)], this.previous)

  }

  isGreaterThanOrEquals(value: any): SimpleFiltration {
    return new SimpleFiltration([new FilterRequest(this.field, value, FilterOperation.GTE)], this.previous)
  }

  isLessThan(value: any): SimpleFiltration {
    return new SimpleFiltration([new FilterRequest(this.field, value, FilterOperation.LT)], this.previous)
  }

  isLessThanOrEquals(value: any): SimpleFiltration {
    return new SimpleFiltration([new FilterRequest(this.field, value, FilterOperation.LTE)], this.previous)
  }

  isEmpty(): SimpleFiltration {
    return new SimpleFiltration([new FilterRequest(this.field, '*****', FilterOperation.EMPTY)], this.previous)
  }

  between(min: any, max: any): SimpleFiltration {
    return new SimpleFiltration([
      new FilterRequest(this.field, min, FilterOperation.GTE),
      new FilterRequest(this.field, max, FilterOperation.LTE)
    ], this.previous)
  }

  betweenRightExclusive(min: any, max: any): SimpleFiltration {
    return new SimpleFiltration([
      new FilterRequest(this.field, min, FilterOperation.GT),
      new FilterRequest(this.field, max, FilterOperation.LTE)
    ], this.previous)
  }

  betweenLeftExclusive(min: any, max: any): SimpleFiltration {
    return new SimpleFiltration([
      new FilterRequest(this.field, min, FilterOperation.GTE),
      new FilterRequest(this.field, max, FilterOperation.LT)
    ], this.previous)
  }

  betweenExclusive(min: any, max: any): SimpleFiltration {
    return new SimpleFiltration([
      new FilterRequest(this.field, min, FilterOperation.GT),
      new FilterRequest(this.field, max, FilterOperation.LT)
    ], this.previous)
  }

  in(...value: any): SimpleFiltration {
    return new SimpleFiltration([
      new FilterRequest(this.field, [...value], FilterOperation.IN)
    ], this.previous)
  }

  notIn(...value: any): SimpleFiltration {
    return new SimpleFiltration([
      new FilterRequest(this.field, [...value], FilterOperation.NOT_IN)
    ], this.previous)
  }

  startsWith(value: any): SimpleFiltration {
    return new SimpleFiltration([new FilterRequest(this.field, value, FilterOperation.BEGINS_WITH_CASEINS)], this.previous);
  }

  contains(value: any): SimpleFiltration {
    return new SimpleFiltration([new FilterRequest(this.field, value, FilterOperation.CONTAINS)], this.previous);
  }
}
