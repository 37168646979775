import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CreatableDropdownService} from "../../services/creatable-dropdown-service/creatable-dropdown.service";
import {AutoCompleteModule} from "primeng/autocomplete";
import {NamedElement} from "../../models/named-element";
import {FormsModule} from "@angular/forms";
import {Dropdown, DropdownChangeEvent, DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";
import {CreatableDropdownOutput} from "./creatable-dropdown.output";

@Component({
  selector: 'bop-creatable-dropdown',
  standalone: true,
  imports: [
    AutoCompleteModule,
    FormsModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    TooltipModule
  ],
  templateUrl: './creatable-dropdown.component.html',
  styleUrl: './creatable-dropdown.component.scss'
})
export class CreatableDropdownComponent implements OnInit {
  @Input() service: CreatableDropdownService<NamedElement, any> | undefined;
  @Input() placeholder: string | undefined;
  @Input() canCreateNew = true;
  @Input() canFilter = true;

  @ViewChild('dropdown') dropdown: Dropdown | undefined;

  @Output() onSelect: EventEmitter<CreatableDropdownOutput> = new EventEmitter();

  allItems: NamedElement[] = [];
  filteredItems: NamedElement[] = [];
  selectedValue: NamedElement | NamedElement[] | null = null;
  filterValue = '';

  ngOnInit() {
    this.service?.getAll().subscribe(items => {
      console.log('updating all selected items');
      this.allItems = items.content;
      this.filteredItems = this.allItems;
    })
  }

  filterItems($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      this.selectItem();
      return;
    }
    this.filteredItems = this.allItems.filter(
      (measurement) => measurement.name.toLowerCase().startsWith(this.filterValue)
    );
  }

  selectItem() {
    console.log('in select item', this.selectedValue);

    if (!this.canCreateNew) {
      return;
    }

    if (this.canFilter && this.filterValue.trim() === '') {
      return;
    }

    if (this.checkValueInItems()) {
      return;
    }

    this.service?.create(new NamedElement(0, this.filterValue)).subscribe(res => {
      this.filterValue = res.name;

      console.log(res, 'responseeeee');

      if (this.checkValueInItems()) {
        return;
      }

      this.allItems.push(res);
      this.filteredItems = this.allItems;


      this.dropdown?.hide(true);
      this.filterValue = '';

      this.emitSelectedValue(res);
    })
  }

  checkValueInItems(): boolean {
    const valueInItems = this.allItems.find(v => v.name.trim() === this.filterValue.trim());
    if (valueInItems !== undefined) {
      this.selectedValue = valueInItems;
      this.dropdown?.hide(true);

      this.emitSelectedValue(valueInItems);

      return true;
    }
    return false;
  }

  setSelectedValue($event: DropdownChangeEvent) {
    console.log('setting selected value', this.selectedValue);
    this.emitSelectedValue($event.value);
  }

  emitSelectedValue(value: any) {
    this.selectedValue = value;
    const output = new CreatableDropdownOutput();

    output.singleOutput = this.selectedValue as NamedElement || new NamedElement();

    this.onSelect.emit(output);
  }
}

