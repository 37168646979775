import {createAction, props} from "@ngrx/store";
import {PageableResponse} from "../../models/pageable/pageable.response";
import {MunicipalityResponse} from "../../models/municipality.response";
import {SettlementResponse} from "../../models/settlement.response";
import {ResidentialAreaResponse} from "../../models/residential-area.response";

export const getMunicipalities = createAction(
  '[Municipalities_Get] Get municipalities',
  props<{ request: string }>()
);

export const getMunicipalitiesSuccess = createAction(
  '[Municipalities_Get_Success] Get municipalities success',
  props<{ response: PageableResponse<MunicipalityResponse> }>()
);

export const getSettlements = createAction(
  '[Settlements_Get] Get settlements by districts',
  props<{ request: { municipality: MunicipalityResponse, namePrefix: string | undefined | null } }>()
);

export const getSettlementsSuccess = createAction(
  '[Settlements_Get] Get settlements by districts success',
  props<{ response: PageableResponse<SettlementResponse> }>()
);

export const getResidentialAreas = createAction(
  '[ResidentialAreas_Get] Get residential areas by settlements',
  props<{ request: { settlement: SettlementResponse, namePrefix: string | undefined | null } }>()
);

export const getResidentialAreasSuccess = createAction(
  '[ResidentialAreas_Get_Success] Get residential areas by settlements success',
  props<{ response: PageableResponse<ResidentialAreaResponse> }>()
);
