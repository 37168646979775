import {Component, Input} from '@angular/core';
import {CategoryResponse} from "../../../models/category.response";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {RadioButtonModule} from "primeng/radiobutton";
import {CategoryRequest} from "../../../models/category.request";
import {FormsModule} from "@angular/forms";
import {AcceptRejectButtonsComponent} from "../../accept-reject-buttons/accept-reject-buttons.component";

@Component({
  selector: 'bop-select-new-categories-tag-parent',
  standalone: true,
  imports: [
    RadioButtonModule,
    FormsModule,
    AcceptRejectButtonsComponent
  ],
  templateUrl: './select-new-categories-tag-parent.component.html',
  styleUrl: './select-new-categories-tag-parent.component.scss'
})
export class SelectNewCategoriesTagParentComponent {
  public newItemName = '';
  public possibleParents: CategoryResponse[] = [];
  public selectedParent: CategoryResponse | undefined;

  constructor(private dialogRef: DynamicDialogRef<SelectNewCategoriesTagParentComponent>,
              private dialogConfig: DynamicDialogConfig) {
    this.newItemName = this.dialogConfig.data.newItemName;
    this.possibleParents = this.dialogConfig.data.possibleParents;
  }

  accept() {
    this.dialogRef.close(this.selectedParent);
  }

  reject() {
    this.dialogRef.close();
  }
}
