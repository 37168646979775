import { Component } from '@angular/core';
import {DataGridTrait} from "../../../shared/services/grid.service";
import {ProfileDonationInfoResponse, ProfileResponse} from "../../profile/profile.response";
import {DonationsService} from "../donations.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {tap} from "rxjs";
import {MessageService} from "primeng/api";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";

@Component({
  selector: 'bop-donations-wishes',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    SpinnerOverlay
  ],
  templateUrl: './donations-wishes.component.html',
  styleUrl: './donations-wishes.component.scss'
})
export class DonationsWishesComponent {
  grid: DataGridTrait<ProfileResponse> = new DataGridTrait<ProfileResponse>(
    this.donationsService.getAllTrustees.bind(this.donationsService),
    this.route,
    this.router
  );
  loading = false;

  constructor(
    private donationsService: DonationsService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.grid.onLoadingChange().subscribe(loading => this.loading = loading);
  }

  getWishesArray(wishes: string) {
    if (!wishes) {
      return [];
    }
    return wishes.split('*').filter(w => w !== '');
  }

  getDonationInfos(donations: ProfileDonationInfoResponse[] | undefined): string[] {
    if (!donations) {
      return [];
    }
    return donations
      .map(d => d.donationInfo)
      .filter(info => info && info.trim() !== '');
  }

  saveAs(action: 'download' | 'email') {
    this.loading = true;
    const content = this.grid.pagedData.content;
    this.grid.pagedData.content = [];
    this.donationsService.saveWishes(action).pipe(
      tap(() => this.loading = false),
      tap(() => this.grid.pagedData.content = content),
    ).subscribe({
      next: () => this.messageService.add({
        severity: 'success',
        summary: 'Успешно запазване',
        detail: `Успешно запазихте справката като ${action === 'download' ? 'файл' : 'писмо.'}`
      }),
      error: () => this.messageService.add({
        severity: 'error',
        summary: 'Грешка при запазване',
        detail: `За съжаление възникна грешка при запазване на справката.`
      })
    })
  }
}
