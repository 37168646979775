<h2>Разфасовки</h2>

@if (!canCreatePackaging) {
  <p class="font-italic text-yellow-300">Моля, запазете детайлите за продукта, за да може да създадете разфасовка</p>
}

<div class="flex justify-content-end mb-7">
  <p-button [disabled]="!canCreatePackaging" icon="pi pi-plus" label="Добавяне на разфасовка" (click)="packagingAdd.emit()"></p-button>
</div>

@if (packagings) {
  <bop-market-packagings-list [packagings]="packagings || []"></bop-market-packagings-list>
}
