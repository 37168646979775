<form (ngSubmit)="onSubmit()" [formGroup]="packagingForm" class="flex flex-column gap-3 align-items-center">
  <bop-form-row-wrapper>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-box"></i>
      </p-inputGroupAddon>
      <bop-creatable-dropdown-single placeholder="Тип опаковка" [style]="{width: '100%'}"
                                     [service]="packagingTypeService"
                                     (onSelect)="selectPackagingType($event)"></bop-creatable-dropdown-single>
    </p-inputGroup>
    <!--      <small-->
    <!--        *ngIf="packagingForm.get('typeOfPackaging')?.hasError('required') && packagingForm.get('typeOfPackaging')?.touched"-->
    <!--        class="text-red-500">-->
    <!--        Типът опаковка е задължително да бъде попълнен.-->
    <!--      </small>-->

    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-objects-column"></i>
      </p-inputGroupAddon>
      <input disabled pInputText placeholder="Клас - {{packagingForm.get('productsClass')?.value}}">
    </p-inputGroup>

    <!--      <small-->
    <!--        *ngIf="packagingForm.get('productsClass')?.hasError('required') && packagingForm.get('productsClass')?.touched"-->
    <!--        class="text-red-500">-->
    <!--        Класът на опаковката е задължително да бъде посочен.-->
    <!--      </small>-->
  </bop-form-row-wrapper>

  <bop-form-row-wrapper>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-sort-numeric-up-alt"></i>
      </p-inputGroupAddon>
      <input formControlName="quantityPerPackaging" pInputText placeholder="Количество в опаковка">
    </p-inputGroup>

    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-sort-numeric-up-alt"></i>
      </p-inputGroupAddon>
      <input formControlName="stockAvailable" pInputText placeholder="Брой в наличност">
    </p-inputGroup>
  </bop-form-row-wrapper>

  <!--  <bop-form-row-wrapper>-->
  <!--    <small-->
  <!--      *ngIf="packagingForm.get('quantityPerPackaging')?.hasError('required') && packagingForm.get('quantityPerPackaging')?.touched"-->
  <!--      class="text-red-500">-->
  <!--      Количеството в опаковка е задължително за посочване.-->
  <!--    </small>-->
  <!--    <small-->
  <!--      *ngIf="packagingForm.get('stockAvailable')?.hasError('required') && packagingForm.get('stockAvailable')?.touched"-->
  <!--      class="text-red-500">-->
  <!--      Наличността е задължително да бъде посочена.-->
  <!--    </small>-->
  <!--  </bop-form-row-wrapper>-->


  <bop-form-row-wrapper>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-money-bill"></i>
      </p-inputGroupAddon>
      <input formControlName="price" pInputText placeholder="Цена">
    </p-inputGroup>
  </bop-form-row-wrapper>
  <!--  <small *ngIf="packagingForm.get('price')?.hasError('required') && packagingForm.get('price')?.touched"-->
  <!--         class="text-red-500">-->
  <!--    Цената е задължително да бъде посочена.-->
  <!--  </small>-->
  <!--  <small *ngIf="packagingForm.get('price')?.hasError('min') && packagingForm.get('price')?.touched"-->
  <!--         class="text-red-500">-->
  <!--    Цената трябва да бъде по-голяма или равна на 0.01 лв.-->
  <!--  </small>-->

  <section
    class="w-full flex flex-column justify-content-start gap-3">
    <p>Актуална снимка на разфасофката:</p>
    <bop-photo-choice (fileSelectedEmitter)="selectImage($event)"></bop-photo-choice>
  </section>

  <p-button [disabled]="packagingForm.invalid" icon="pi pi-save" label="Запази разфасовка" type="submit"></p-button>
</form>
