import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuItem, MenuItemCommandEvent} from "primeng/api";
import {Theme, ThemeType} from "../../models/theme.type";
import {MenubarModule} from "primeng/menubar";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import {UserStatisticsComponent} from "../../../private/profile/user-statistics/user-statistics.component";
import {StatisticsResponse} from "../../models/statistics.response";
import {DividerModule} from "primeng/divider";
import {HeaderProfileStatisticsComponent} from "./header-profile-statistics/header-profile-statistics.component";

@Component({
  selector: 'bop-header',
  standalone: true,
  imports: [
    MenubarModule,
    MenuModule,
    ButtonModule,
    UserStatisticsComponent,
    DividerModule,
    HeaderProfileStatisticsComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() menuItems: MenuItem[] | undefined;
  @Input() profileItems: MenuItem[] | undefined;
  @Input() profileMeta: StatisticsResponse | undefined;
  @Input() theme: Theme = ThemeType.LIGHT();

  @Output() themeChange: EventEmitter<Theme> = new EventEmitter();
  @Output() sidebarVisibility: EventEmitter<boolean> = new EventEmitter();

  callMenuItemCommand(command: ((event: MenuItemCommandEvent) => void) | undefined) {
    if (command) {
      command({index: 0, item: undefined, originalEvent: undefined})
    }
  }
}

