import {Component, inject, Input} from '@angular/core';
import {DataGridTrait} from "../../../shared/services/grid.service";
import {ProfileResponse} from "../../profile/profile.response";
import {MarketService} from "../market.service";
import {PackagingResponse} from "../packaging.response";
import {ProductClassComponent} from "../../../shared/components/product-class/product-class.component";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {FileUrlPipe} from "../../../shared/pipes/file-url.pipe";

@Component({
  selector: 'bop-market-packagings-list',
  standalone: true,
  imports: [
    ProductClassComponent,
    SharedModule,
    TableModule,
    FileUrlPipe
  ],
  templateUrl: './market-packagings-list.component.html',
  styleUrl: './market-packagings-list.component.scss'
})
export class MarketPackagingsListComponent {
  @Input() public packagings: PackagingResponse[] = [];
}
