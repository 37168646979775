import {Injectable} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {PageableResponse} from "../../models/pageable/pageable.response";
import {CreatableDropdownService} from "./creatable-dropdown.service";
import {NamedElement} from "../../models/named-element";
import {LocationResponse} from "../../models/location/location.response";
import {Store} from "@ngrx/store";
import {ProfileState} from "../../../private/profile/store/profile.state";
import {selectCurrentUser} from "../../../private/profile/store/profile.reducer";
import {FileResponse} from "../../models/file.response";
import {SettlementResponse} from "../../models/settlement.response";
import {ResidentialAreaResponse} from "../../models/residential-area.response";
import {RoleResponse} from "../../../private/profile/profile.response";
import {LocationService} from "../location.service";

@Injectable({providedIn: 'root'})
export class OriginService implements CreatableDropdownService<LocationResponse, NamedElement> {
  constructor(private store: Store<ProfileState>,
              private locationService: LocationService) {
  }

  userProfile$ = this.store.select(selectCurrentUser);

  public getAll(): Observable<PageableResponse<LocationResponse>> {
    return this.userProfile$.pipe(map(userProfile => {
      const locationsPage: PageableResponse<LocationResponse> = PageableResponse.empty();
      userProfile?.locations.forEach(l => locationsPage.content.push(LocationResponse.create(l)));
      return locationsPage;
    }))
  }

  public create(): Observable<LocationResponse> {
    return this.locationService.createEditLocation();
  }
}
