import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {QueryFormattingUtility} from "../models/filtration/query-formatting.utility";
import {Filtration, InvokableFiltration} from "../models/filtration/filtration";
import {PageableRequest} from "../models/pageable/pageable.request";
import {Observable} from "rxjs";
import {PageableResponse} from "../models/pageable/pageable.response";
import {TagCreateRequest} from "../models/tag-create.request";
import {TagStrippedResponse} from "../models/tag-stripped.response";

@Injectable({providedIn: 'root'})
export class TagService {
  constructor(private http: HttpClient,
              private queryFormattingUtility: QueryFormattingUtility,
  ) {
  }

  getAll(filtration: InvokableFiltration = Filtration.empty(), page: PageableRequest = PageableRequest.first()): Observable<PageableResponse<TagStrippedResponse>> {
    return this.http.get<PageableResponse<TagStrippedResponse>>('api/tags', {params: this.queryFormattingUtility.formatQuery(page, filtration)});
  }

  create(payload: TagCreateRequest): Observable<TagStrippedResponse> {
    return this.http.post<TagStrippedResponse>('api/tags', payload);
  }
}
