import { Component } from '@angular/core';
import {CreatableDropdownComponent} from "../creatable-dropdown.component";
import {ButtonModule} from "primeng/button";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "primeng/api";
import {TooltipModule} from "primeng/tooltip";
import {NamedElement} from "../../../models/named-element";

@Component({
  selector: 'bop-creatable-dropdown-single',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownModule,
    InputTextModule,
    ReactiveFormsModule,
    SharedModule,
    TooltipModule,
    FormsModule
  ],
  templateUrl: './creatable-dropdown-single.component.html',
  styleUrl: './creatable-dropdown-single.component.scss'
})
export class CreatableDropdownSingleComponent extends CreatableDropdownComponent {
  override selectedValue: NamedElement | null = null;

  constructor() {
    super();
  }
}
