<bop-market-breadcrumb [category]="(currentSelectedCategory$ | async) || undefined"></bop-market-breadcrumb>

@if (loadedProducts.length < 1) {
  <p class="font-italic text-yellow-300">За съжаление няма намерени продукти в избраната категория</p>
}

<section class="grid p-3">
  @for (product of loadedProducts; track product) {
    <div class="col-12 md:col-6 lg:col-3">

      <p-card [header]="product.name" [subheader]="product.brand">
        <ng-template pTemplate="header">
          <img alt="Card" class="w-full h-20rem" [style]="{'object-fit': 'cover'}" [src]="product.productImages[0].url | fileUrl"/>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="flex gap-3 mt-1">
            <p-button [routerLink]="'/private/market/products/' + product.id" label="Научи повече" class="w-full" styleClass="w-full"/>
          </div>
        </ng-template>
      </p-card>

    </div>
  }
</section>
<!--<section class="p-2 flex justify-content-center gap-2">-->
<!--  <p-card header="Бял равнец" subheader="15 лв." [style]="{ width: '360px' }">-->
<!--    <ng-template pTemplate="header">-->
<!--      <img alt="Card" src="https://bulgarherbs.bg/article_images/1455863272.jpg"/>-->
<!--    </ng-template>-->
<!--    <ng-template pTemplate="footer">-->
<!--      <div class="flex gap-3 mt-1">-->
<!--        <p-button label="Научи повече" class="w-full" styleClass="w-full"/>-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </p-card>-->

<!--  <p-card header="Лайка" subheader="15 лв." [style]="{ width: '360px' }">-->
<!--    <ng-template pTemplate="header">-->
<!--      <img class="h-17rem object-contain" alt="Card" src="https://recepti.domashnakunyasdani.com/wp-content/uploads/2023/06/chamomile-7198844_1280.jpg"/>-->
<!--    </ng-template>-->
<!--    <ng-template pTemplate="footer">-->
<!--      <div class="flex gap-3 mt-1">-->
<!--        <p-button label="Научи повече" class="w-full" styleClass="w-full"/>-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </p-card>-->

<!--  <p-card header="Мащерка" subheader="15 лв." [style]="{ width: '360px' }">-->
<!--    <ng-template pTemplate="header">-->
<!--      <img alt="Card" class="h-17rem" src="https://agri.bg/media/2020/04/12/514141/780x440.jpg"/>-->
<!--    </ng-template>-->
<!--    <ng-template pTemplate="footer">-->
<!--      <div class="flex gap-3 mt-1">-->
<!--        <p-button label="Научи повече" class="w-full" styleClass="w-full"/>-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </p-card>-->

<!--  <p-card header="Жълт кантарион" subheader="15 лв." [style]="{ width: '360px' }">-->
<!--    <ng-template pTemplate="header">-->
<!--      <img alt="Card" src="https://mursalski-tea-trigrad.com/files/5fc679c094960.jpg"/>-->
<!--    </ng-template>-->
<!--    <ng-template pTemplate="footer">-->
<!--      <div class="flex gap-3 mt-1">-->
<!--        <p-button label="Научи повече" class="w-full" styleClass="w-full"/>-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </p-card>-->
<!--</section>-->
