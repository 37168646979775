import {SettlementResponse} from "../settlement.response";
import {RoleResponse} from "../../../private/profile/profile.response";
import {FileResponse} from "../file.response";
import {ResidentialAreaResponse} from "../residential-area.response";
import {NamedElement} from "../named-element";

export class LocationResponse implements NamedElement {
  constructor(
    public id: number = 0,
    public name: string = '',
    public userId: number = 0,
    public address: string = '',
    public comment: string = '',
    public coordinates: string = '',
    public locationImage: FileResponse = new FileResponse(),
    public token: string = '',
    public settlement: SettlementResponse = new SettlementResponse(),
    public residentialArea: ResidentialAreaResponse = new ResidentialAreaResponse(),
    public locationRoles: RoleResponse[] = [],
    public isDraft = false
  ) {
    this.populateName();
  }

  static create(l: LocationResponse) {
    return new LocationResponse(
      l.id,
      l.name,
      l.userId,
      l.address,
      l.comment,
      l.coordinates,
      l.locationImage,
      l.token,
      l.settlement,
      l.residentialArea,
      l.locationRoles,
      l.isDraft
    );
  }

  populateName() {
    this.name = this.address ? `${this.settlement.name}, ${this.address}` : this.settlement.name;
  }
}

