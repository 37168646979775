<p-multiSelect (onChange)="setSelectedValue($event)"
               [filter]="true"
               [options]="filteredItems"
               [style]="{'width': '100%'}"
               display="chip"
               [(ngModel)]="selectedValue"
               optionLabel="name"
               [placeholder]="placeholder">
  <ng-template pTemplate="filter" let-options="options">
    <div class="flex gap-1">
      @if (canFilter) {
        <div class="p-inputgroup" (click)="$event.stopPropagation()">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <input
            [style]="{width: '100%'}"
            pTooltip="За да създадете нов, използвайте бутона + или натиснете Enter"
            type="text"
            pInputText
            placeholder="Търсене по низ"
            [(ngModel)]="filterValue"
            (keyup)="filterItems($event)"
          />
        </div>
      }

      @if (canCreateNew) {
        <p-button pTooltip="Създаване на нов" (onClick)="selectItem()" icon="pi pi-plus"
                  severity="secondary"></p-button>
      }
    </div>
  </ng-template>
</p-multiSelect>
