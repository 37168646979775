import {PackagingResponse} from './packaging.response';
import {UserFlatResponse} from "../donations/user-flat.response";
import {CategoryFlatResponse} from "../../shared/models/category-flat.response";
import {LocationResponse} from "../../shared/models/location/location.response";
import {FileResponse} from "../../shared/models/file.response";
import {TagStrippedResponse} from "../../shared/models/tag-stripped.response";
import {MeasurementResponse} from "../../shared/models/measurement.response";

export class ProductResponse {
  constructor(
    public id: number = 0,
    public creator: UserFlatResponse = new UserFlatResponse(),
    public categories: CategoryFlatResponse[] = [],
    public productId: string = '',
    public name: string = '',
    public brand: string = '',
    public tags: TagStrippedResponse[] = [],
    public qualityIndex: { id: number, type: string }[] = [],
    public measurement: MeasurementResponse = new MeasurementResponse(),
    public pricePerTradeUnit: number = 0,
    public originLocation: LocationResponse = new LocationResponse(),
    public phoneCallAvailability?: string,
    public producerImage: FileResponse = new FileResponse(),
    public productImages: FileResponse[] = [],
    public upcomingEvents?: string,
    public exchangeCurrencies: Set<number> = new Set(),
    public ingredients?: string,
    public vibrationRequirements?: string,
    public otherRequirements?: string,
    public producingTechnology?: string,
    public lastUpdatedOn: Date = new Date(),
    public productsUniqueness?: string,
    public productsApplication?: string,
    public otherInfoTypes: Set<number> = new Set(),
    public packagings: PackagingResponse[] = []
  ) {
  }
}
