import {RoleRequest} from "../../../private/profile/profile.request";
import {LocationResponse} from "./location.response";

export class LocationRequest {
  constructor(
    public id: number | null = 0,
    public address: string = '',
    public comment: string = '',
    public coordinates: string = '',
    public settlementId: number = 0,
    public residentialAreaId: number = 0,
    public locationRoles: RoleRequest[] = [],
    public locationImageId: number | null = null
  ) {

  }

  static fromResponse(response: LocationResponse) {
    return new LocationRequest(
      response.id,
      response.address,
      response.comment,
      response.coordinates,
      response.settlement.id,
      response.residentialArea?.id,
      response.locationRoles.map(
        role => new RoleRequest(role.roleId, role.reason)
      ),
      response.locationImage?.id
    );
  }
}

