<bop-form-row-wrapper>
  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-map"></i>
    </p-inputGroupAddon>
    <p-autoComplete placeholder="Община"
                    [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                    [formControl]="municipalitySearchControl"
                    [suggestions]="(municipalities$ | async)!"
                    #municipality
                    (onSelect)="onMunicipalitySelect($event); municipality.hide(); assignAutocompleteFields(municipality, settlement, residentialArea || null)"
                    (onFocus)="municipality.show()"
                    (onKeyUp)="municipality.show()"
                    optionLabel="name"
                    appendTo="body">
    </p-autoComplete>
  </p-inputGroup>

  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-map"></i>
    </p-inputGroupAddon>
    <p-autoComplete placeholder="Населено място"
                    [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                    [formControl]="settlementSearchControl"
                    [suggestions]="(settlements$ | async)!"
                    #settlement
                    (onSelect)="onSettlementSelect($event); settlement.hide()"
                    (onFocus)="settlement.show()"
                    (onKeyUp)="settlement.show()"
                    optionLabel="name"
                    appendTo="body">
    </p-autoComplete>
  </p-inputGroup>

  <p-inputGroup *ngIf="selectedSettlement && settlementHasResidentialAreas">
    <p-inputGroupAddon>
      <i class="pi pi-map"></i>
    </p-inputGroupAddon>
    <p-autoComplete placeholder="Квартал"
                    [inputStyle]="{'width': '100%'}" [style]="{'width': '100%'}"
                    [formControl]="residentialAreaSearchControl"
                    [suggestions]="(residentialAreas$ | async)!"
                    #visibleResidentialArea
                    (onSelect)="onResidentialAreaSelect($event); visibleResidentialArea.hide()"
                    (onFocus)="visibleResidentialArea.show()"
                    (onKeyUp)="visibleResidentialArea.show()"
                    optionLabel="name"
                    appendTo="body">
    </p-autoComplete>
  </p-inputGroup>
</bop-form-row-wrapper>

<p-autoComplete #residentialArea [style]="{'display': 'none'}"></p-autoComplete>
