import { Component } from '@angular/core';

@Component({
  selector: 'bop-product-class',
  standalone: true,
  imports: [],
  templateUrl: './product-class.component.html',
  styleUrl: './product-class.component.scss'
})
export class ProductClassComponent {

}
