<bop-form-row-wrapper>
  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-list"></i>
    </p-inputGroupAddon>
<!--    <p-multiSelect (onChange)="loadProductTypes($event)"-->
<!--                   [filter]="true"-->
<!--                   [options]="subcategories"-->
<!--                   [style]="{'width': '100%'}"-->
<!--                   display="chip"-->
<!--                   formControlName="categoryType"-->
<!--                   optionLabel="name"-->
<!--                   placeholder="Подкатегории">-->
<!--    </p-multiSelect>-->
    <bop-creatable-dropdown-multiple [style]="{width: '100%'}" placeholder="Подкатегории"
                                     [service]="categorySelectionService"
                                     (onSelect)="selectSubcategory($event)"></bop-creatable-dropdown-multiple>

  </p-inputGroup>

  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-list"></i>
    </p-inputGroupAddon>
    <bop-creatable-dropdown-multiple [style]="{width: '100%'}" placeholder="Тип продукт"
                                     [service]="productTypeSelectionService"
                                     (onSelect)="selectProductType($event)"></bop-creatable-dropdown-multiple>
  </p-inputGroup>

  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-plus-circle"></i>
    </p-inputGroupAddon>
    <bop-creatable-dropdown-multiple [style]="{width: '100%'}" placeholder="Тагове"
                                     [service]="tagSelectionService"
                                     (onSelect)="selectTag($event)"></bop-creatable-dropdown-multiple>

  </p-inputGroup>
</bop-form-row-wrapper>
