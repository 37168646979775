import {Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {LayoutStore} from "../shared/store/layout/layout.store";

@Component({
  selector: 'bop-public',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './public.component.html',
  styleUrl: './public.component.scss'
})
export class PublicComponent implements OnInit{
  readonly layoutStore = inject(LayoutStore);

  public ngOnInit(): void {
    this.layoutStore.setHeaderItems([]);
    this.layoutStore.setSidebarExpanded(false);
    this.layoutStore.setSidebarPanelMenuItems([]);
    this.layoutStore.setProfileItems([]);
  }

}
