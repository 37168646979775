// import {inject, Injectable} from "@angular/core";
// import {catchError, map, mergeMap, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";

import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {
  getAllCategories,
  getAllCategoriesSuccess,
  getAllMeasurements,
  getAllMeasurementsSuccess, getCurrentSearchCategoryDetails, getCurrentSearchCategoryDetailsSuccess,
  loadNestedCategoriesMenuItems, loadNestedCategoriesMenuItemsSuccess,
  loadSelectableCategoriesMenuItems,
  loadSelectableCategoriesMenuItemsSuccess
} from "./market.actions";
import {map, switchMap, tap} from "rxjs";
import {CategoriesService} from "../../../shared/services/categories.service";
import {Filtration} from "../../../shared/models/filtration/filtration";
import {MeasurementService} from "../../../shared/services/creatable-dropdown-service/measurement.service";
import {selectAllCategories} from "./market.reducer";

@Injectable()
export class MarketEffects {
  constructor(private actions$: Actions,
              private store: Store,
              private router: Router,
              private measurementsService: MeasurementService,
              private categoriesService: CategoriesService) {
  }

  public loadMeasurements = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllMeasurements),
      switchMap(() =>
        this.measurementsService.getAll().pipe(
          map(response => getAllMeasurementsSuccess({measurements: response.content}))
        )
      )
    )
  );

  public loadCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllCategories),
      switchMap(() =>
        this.categoriesService.getAll(new Filtration().field('parent.id').isEmpty()).pipe(
          map(response => getAllCategoriesSuccess({categories: response.content}))
        )
      )
    )
  );

  public loadCurrentlySelectedCategory = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentSearchCategoryDetails),
      switchMap(({categoryId}) =>
        this.categoriesService.getOne(categoryId).pipe(
          map(category => {
            console.log('currently selected category', category);
            return getCurrentSearchCategoryDetailsSuccess({category})
          })
        )
      )
    )
  );

  public loadMenuSelectableCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSelectableCategoriesMenuItems),
      switchMap(() =>
        this.store.select(selectAllCategories).pipe(
          map(response => loadSelectableCategoriesMenuItemsSuccess({menuItems: this.categoriesService.mapCategoriesToMenuItems(response)}))
        )
      )
    )
  );

  public loadMenuNestedCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNestedCategoriesMenuItems),
      switchMap(() =>
        this.store.select(selectAllCategories).pipe(
          map(response => loadNestedCategoriesMenuItemsSuccess({menuItems: this.categoriesService.mapCategoriesToNestedMenuItems(response)}))
        )
      )
    )
  );

}
