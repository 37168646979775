import {Component} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {CreatableDropdownComponent} from "../creatable-dropdown.component";
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {TooltipModule} from "primeng/tooltip";
import {CreatableDropdownOutput} from "../creatable-dropdown.output";
import {NamedElement} from "../../../models/named-element";
import {distinctUntilChanged, filter} from "rxjs";

@Component({
  selector: 'bop-creatable-dropdown-multiple',
  standalone: true,
  imports: [
    DropdownModule,
    MultiSelectModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    TooltipModule
  ],
  templateUrl: './creatable-dropdown-multiple.component.html',
  styleUrl: './creatable-dropdown-multiple.component.scss'
})
export class CreatableDropdownMultipleComponent extends CreatableDropdownComponent {
  override selectedValue: NamedElement[] | null = null;

  constructor() {
    super();
  }

  override ngOnInit() {

    this.service?.getAll()
      .pipe(filter(items => items.content[0] !== null))
      .subscribe(items => {
        console.log('Updating all selected items');

        // Update allItems and filteredItems
        this.allItems = items.content;
        this.filteredItems = this.allItems;

        console.log('Selected value before update', this.selectedValue);

        // Filter out selected items that are no longer in allItems

        const newSelectedValues: NamedElement[] = [];
        const oldSelectedValuesIds = this.selectedValue?.map(v => v.id);

        this.allItems.filter(i => i !== null).forEach(i => {
          if (oldSelectedValuesIds?.includes(i.id)) {
            newSelectedValues.push(i);
          }
        })

        this.selectedValue = newSelectedValues;

        console.log('All Items:', this.allItems);
        console.log('Selected Value After Mapping:', this.selectedValue);
      });
  }

  override emitSelectedValue(value: any) {
    const output = new CreatableDropdownOutput();

    if (Array.isArray(value)) {
      this.selectedValue = value;
    } else {
      this.selectedValue = [value];
    }

    console.log('new selected value', this.selectedValue);

    output.multipleOutput = this.selectedValue;

    this.onSelect.emit(output);
  }
}
