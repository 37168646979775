import {MenuItem} from "primeng/api";
import {StatisticsResponse} from "../../models/statistics.response";
import {patchState, signalStore, withMethods, withState} from "@ngrx/signals";

type LayoutState = {
  headerItems: MenuItem[];
  sidebarPanelMenuItems: MenuItem[];
  sidebarTieredMenuItems: MenuItem[];
  profileItems: MenuItem[];
  profileMeta: StatisticsResponse;
  profileName: string;
  sidebarExpanded: boolean;
  tieredMenuSelectable: boolean;
  sidebarSearchBar: boolean;
  sidebarText: string
}

const initialState: LayoutState = {
  headerItems: [],
  sidebarPanelMenuItems: [],
  sidebarTieredMenuItems: [],
  profileItems: [],
  profileMeta: new StatisticsResponse(),
  profileName: '',
  sidebarExpanded: false,
  tieredMenuSelectable: false,
  sidebarSearchBar: false,
  sidebarText: ''
}

export const LayoutStore = signalStore(
  {providedIn: 'root'},
  withState(initialState),
  withMethods(store => ({
    setHeaderItems(items: MenuItem[]): void {
      patchState(store, (state) => ({headerItems: items}))
    },
    setSidebarPanelMenuItems(items: MenuItem[]): void {
      patchState(store, (state) => ({sidebarPanelMenuItems: items}))
    },
    setSidebarTieredMenuItems(items: MenuItem[]): void {
      patchState(store, (state) => ({sidebarTieredMenuItems: items}))
    },
    setProfileItems(items: MenuItem[]): void {
      patchState(store, (state) => ({profileItems: items}))
    },
    setProfileMeta(profileMeta: StatisticsResponse): void {
      patchState(store, (state) => ({profileMeta: profileMeta}))
    },
    setProfileName(name: string): void {
      patchState(store, (state) => ({profileName: name}))
    },
    setSidebarExpanded(expanded: boolean): void {
      patchState(store, (state) => ({sidebarExpanded: expanded}))
    },
    setTieredMenuSelectable(selectable: boolean): void {
      patchState(store, (state) => ({tieredMenuSelectable: selectable}))
    },
    setSidebarSearchBar(hasSearchBar: boolean): void {
      patchState(store, (state) => ({sidebarSearchBar: hasSearchBar}))
    },
    setSidebarText(text: string): void {
      patchState(store, (state) => ({sidebarText: text}))
    },
    resetSidebar(): void {
      patchState(store, (state) => ({
        sidebarPanelMenuItems: [],
        sidebarTieredMenuItems: [],
        tieredMenuSelectable: false,
        sidebarSearchBar: false,
        sidebarText: ''
      }))
    }
  }))
)
