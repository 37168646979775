import {Component, EventEmitter, inject, Output} from '@angular/core';
import {AutoComplete, AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import {ButtonModule} from "primeng/button";
import {
  FormRowWrapperComponent
} from "../../../../shared/components/form-wrapper/form-row-wrapper/form-row-wrapper.component";
import {FormWrapperComponent} from "../../../../shared/components/form-wrapper/form-wrapper.component";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CheckboxModule} from "primeng/checkbox";
import {
  SettlementSelectionComponent
} from "../../../../shared/components/settlement-selection/settlement-selection.component";
import {RichEditorComponent} from "../../../../shared/components/rich-editor/rich-editor.component";
import {PhotoChoiceComponent} from "../../../../shared/components/photo-choice/photo-choice.component";
import {MultiSelectModule} from "primeng/multiselect";
import {RichEditorModel} from "../../../../shared/components/rich-editor/rich-editor-model";
import {
  SelectCategoriesTagsComponent
} from "../../../../shared/components/select-categories-tags/select-categories-tags.component";

import {
  CreatableDropdownComponent
} from "../../../../shared/components/creatable-dropdown/creatable-dropdown.component";
import {MeasurementService} from "../../../../shared/services/creatable-dropdown-service/measurement.service";
import {
  CreatableDropdownSingleComponent
} from "../../../../shared/components/creatable-dropdown/creatable-dropdown-single/creatable-dropdown-single.component";
import {
  AcceptedCurrencyService
} from "../../../../shared/services/creatable-dropdown-service/accepted-currency.service";
import {
  CreatableDropdownMultipleComponent
} from "../../../../shared/components/creatable-dropdown/creatable-dropdown-multiple/creatable-dropdown-multiple.component";
import {QualityService} from "../../../../shared/services/creatable-dropdown-service/quality.service";
import {OriginService} from "../../../../shared/services/creatable-dropdown-service/origin.service";
import {CreatableDropdownOutput} from "../../../../shared/components/creatable-dropdown/creatable-dropdown.output";
import {CreateProductRequest} from "../../product-create.request";
import {AccordionModule} from "primeng/accordion";
import {FileService} from "../../../../shared/services/file.service";

@Component({
  selector: 'bop-market-create-product-form',
  standalone: true,
  imports: [
    AutoCompleteModule,
    ButtonModule,
    FormRowWrapperComponent,
    FormWrapperComponent,
    InputGroupAddonModule,
    InputGroupModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    CheckboxModule,
    SettlementSelectionComponent,
    RichEditorComponent,
    PhotoChoiceComponent,
    MultiSelectModule,
    SelectCategoriesTagsComponent,
    CreatableDropdownComponent,
    CreatableDropdownSingleComponent,
    CreatableDropdownMultipleComponent,
    AccordionModule
  ],
  templateUrl: './market-create-product-form.component.html',
  styleUrl: './market-create-product-form.component.scss'
})
export class MarketCreateProductFormComponent {
  public fb = inject(FormBuilder);

  public measurementService = inject(MeasurementService);
  public acceptedCurrencyService = inject(AcceptedCurrencyService);
  public qualityService = inject(QualityService);
  public originService = inject(OriginService);
  public fileService = inject(FileService);

  public ingredientsEditorModel = new RichEditorModel();
  public technologyEditorModel = new RichEditorModel();
  public uniquenessEditorModel = new RichEditorModel();
  public futureEventsEditorModel = new RichEditorModel();
  public applicationEditorModel = new RichEditorModel();

  public subcategoriesIds: number[] = [];
  public productTypeIds: number[] = [];

  public canCall: boolean = false;

  public productImages: number[] = [];

  @Output() saveProductChanges = new EventEmitter<CreateProductRequest>()

  public productForm: FormGroup = this.fb.group({
    categoriesIds: new FormControl<number[]>([], [Validators.required]), // Set type
    name: new FormControl('', [Validators.required]), // Product name
    brand: new FormControl('', [Validators.required]), // Brand
    tagIds: new FormControl<number[]>([], [Validators.required, Validators.maxLength(10)]), // Set type with max length
    qualityIndex: new FormControl(0, [Validators.required]), // Quality index
    measurementId: new FormControl(0, [Validators.required]), // Measurement ID
    isTradeUnit: new FormControl<boolean>(false), // Checkbox for trade unit
    locationId: new FormControl<number>(0, [Validators.required]), // LocationRequest type
    phoneCallAvailability: new FormControl<string | undefined>(''), // Phone call availability
    producerImageId: new FormControl<number | undefined>(undefined), // Producer image ID
    productImageIds: new FormControl<number[] | undefined>(undefined), // Array of product image IDs
    upcomingEvents: new FormControl<string | undefined>(''), // Upcoming events
    exchangeCurrencies: new FormControl<number[]>([], []), // Set of currencies
    ingredients: new FormControl<string | undefined>(this.ingredientsEditorModel.text), // Ingredients
    vibrationRequirements: new FormControl<string | undefined>(''), // Vibration requirements
    otherRequirements: new FormControl<string | undefined>(''), // Other requirements
    producingTechnology: new FormControl<string | undefined>(this.technologyEditorModel.text), // Producing technology
    productsUniqueness: new FormControl<string | undefined>(this.uniquenessEditorModel.text), // Product uniqueness
    productsApplication: new FormControl<string | undefined>(''), // Product application
    otherInfoTypeIds: new FormControl<number[]>([]), // Set of other info type IDs,
    pricePerTradeUnit: new FormControl<number>(0),
    productId: new FormControl<string>('productId' + Math.random())
  });


  addNewSubcategories(categoryIds: number[]) {
    this.subcategoriesIds = categoryIds;
  }

  addNewProductTypes(productTypeIds: number[]) {
    this.productTypeIds = productTypeIds;
  }

  addNewTags(tagIds: number[]) {
    this.productForm.get('tagIds')?.setValue(tagIds);
  }

  selectMeasurement($event: CreatableDropdownOutput) {
    this.productForm.get('measurementId')?.setValue($event.singleOutput.id);
  }

  selectCurrency($event: CreatableDropdownOutput) {
    this.productForm.get('exchangeCurrencies')?.setValue($event.multipleOutput.map(e => e.id));
  }

  selectQuality($event: CreatableDropdownOutput) {
    this.productForm.get('qualityIndex')?.setValue($event.singleOutput.id);
  }

  selectOrigin($event: CreatableDropdownOutput) {
    console.log('selectedOrigin', $event);
    this.productForm.get('locationId')?.setValue($event.singleOutput.id);
  }

  setProducerImage($event: File) {
    this.fileService.uploadFile($event).subscribe(fileResponse => this.productForm.get('producerImageId')?.setValue(fileResponse.id));
  }

  setProductImage($event: File, index: number) {
    this.fileService.uploadFile($event).subscribe(fileResponse => this.productImages[index] = fileResponse.id);
  }

  saveProductDetails() {
    this.productForm.get('categoriesIds')?.setValue([...this.subcategoriesIds, ...this.productTypeIds]);
    this.productForm.get('producingTechnology')?.setValue(this.technologyEditorModel.text);
    this.productForm.get('productsUniqueness')?.setValue(this.uniquenessEditorModel.text);
    this.productForm.get('upcomingEvents')?.setValue(this.futureEventsEditorModel.text);
    this.productForm.get('ingredients')?.setValue(this.ingredientsEditorModel.text);
    this.productForm.get('productsApplication')?.setValue(this.applicationEditorModel.text);
    this.productForm.get('productImageIds')?.setValue(this.productImages.filter(i => !!i));

    this.saveProductChanges.emit(this.productForm.value);
  }

}
