import {Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {MenuItem, PrimeIcons} from "primeng/api";
import {LayoutStore} from "../../shared/store/layout/layout.store";
import {Store} from "@ngrx/store";
import {ProfileState} from "../profile/store/profile.state";
import {selectCurrentUser} from "../profile/store/profile.reducer";

@Component({
  selector: 'bop-donations',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './donations.component.html',
  styleUrl: './donations.component.scss'
})
export class DonationsComponent implements OnInit {
  readonly layoutStore = inject(LayoutStore);
  private currentUser$ = this.store.select(selectCurrentUser);

  private donationsPrimaryItems: MenuItem[] = [
    {
      icon: PrimeIcons.GIFT,
      label: 'ДАРи БОП',
      routerLink: ['private', 'donations' ,'create'],
    },
    {
      icon: PrimeIcons.STAR,
      label: 'Справка БОП размени',
      routerLink: ['private', 'donations' ,'wishes'],
    },
  ]

  private donationsItems: MenuItem[] = [
    {
      icon: PrimeIcons.USER_PLUS,
      label: 'ДАРи с Довереник',
      routerLink: ['private', 'donations', 'invite']
    },
    {
      icon: PrimeIcons.GIFT,
      label: 'ДАРи в БОП',
      routerLink: ['private', 'donations' ,'create'],
    },
    {
      icon: PrimeIcons.USERS,
      label: 'Моите довереници',
      routerLink: ['private', 'donations', 'trustees']
    },
    {
      icon: PrimeIcons.GIFT,
      label: 'Дарения чрез мен',
      routerLink: ['private', 'donations' ,'sent'],
    },
    {
      icon: PrimeIcons.GIFT,
      label: 'Дарения от мен',
      routerLink: ['private', 'donations', 'received'],
    },
    {
      icon: PrimeIcons.STAR,
      label: 'Справка БОП размени',
      routerLink: ['private', 'donations' ,'wishes'],
    },
  ]

  constructor(private store: Store<ProfileState>) {
  }

  ngOnInit(): void {
    this.layoutStore.resetSidebar();
    this.layoutStore.setSidebarPanelMenuItems(this.donationsItems);
    this.layoutStore.setSidebarExpanded(true);

    this.currentUser$.subscribe(user => {
      if (user?.status !== 1 && !user?.admin) {
        this.layoutStore.setSidebarPanelMenuItems(this.donationsPrimaryItems);
        return;
      }

      this.layoutStore.setSidebarPanelMenuItems(this.donationsItems);
    })
  }

}
