<p-tabView>
  <p-tabPanel header="Данни за продукта">
    <bop-form-wrapper>
      <bop-market-create-product-form (saveProductChanges)="saveProduct($event)"></bop-market-create-product-form>
    </bop-form-wrapper>
  </p-tabPanel>

  <p-tabPanel header="Разфасофки">
    <bop-form-wrapper>
      <bop-market-create-packaging [packagings]="packagings" [canCreatePackaging]="!!currentProductId" (packagingAdd)="openPackagingModal()"></bop-market-create-packaging>
    </bop-form-wrapper>
  </p-tabPanel>
</p-tabView>
