// import {MarketState} from "./market.state";
// import {createFeature, createReducer, on} from "@ngrx/store";
// import {
//   getInvitedProfileSuccess,
//   getMyProfile,
//   getMyProfileSuccess,
//   getTrusteeTransfer,
//   getTrusteeTransferError,
//   getTrusteeTransferSuccess,
//   removeSelectedAddress,
//   removeSelectedRole,
//   setSelectedAddress,
//   setSelectedRole,
//   setSelectedWishes,
//   setSelectedWishesBopSoftware,
//   setSelectedWishesWantToBuy,
//   setSelectedWishesWantToExchange,
//   setSelectedWishesWantToSell,
//   updateMyProfile,
//   updateMyProfileError,
//   updateMyProfileSuccess
// } from "./market.actions";
// import {WishPackage} from "../profile-edit/profile-edit-wishes/wish.package";
// import {LocationRequest} from "../../../shared/models/location/location.request";
//
import {MarketState} from "./market.state";
import {createFeature, createReducer, on} from "@ngrx/store";
import {
  getAllCategoriesSuccess,
  getAllMeasurementsSuccess,
  getCurrentSearchCategoryDetailsSuccess,
  loadNestedCategoriesMenuItemsSuccess,
  loadSelectableCategoriesMenuItemsSuccess,
  selectParentCategoryForProductCreation,
  selectProductTypeForProductCreation,
  selectSubcategoryForProductCreation,
  selectTagsForProductCreation, setCurrentSearchWord,
  unselectParentCategoryForProductCreation,
  unselectProductTypeForProductCreation,
  unselectSubcategoryForProductCreation,
  unselectTagsForProductCreation
} from "./market.actions";
import {CategoryResponse} from "../../../shared/models/category.response";
import {TagStrippedResponse} from "../../../shared/models/tag-stripped.response";

const initialState: MarketState = {
  allCategories: [],
  newProductParentCategories: [],
  newProductSubcategories: [],
  newProductTypes: [],
  newProductTags: [],
  categoriesSelectableMenuItems: [],
  categoriesNestedMenuItems: [],
  currentlySelectedCategory: null,
  currentSearchWord: ''
}

const marketFeature = createFeature({
  name: "Market",

  reducer: createReducer(
    initialState,

    on(getAllCategoriesSuccess, (state, {categories}) => {
      const newProductParentCategories = categories.filter(c => state.newProductParentCategories.map(c => c.id).includes(c.id));

      const allNewProductSubcategories: CategoryResponse[][] = [];

      newProductParentCategories.forEach(c => {
        allNewProductSubcategories.push(c.children);
      })

      const newProductSubcategories = allNewProductSubcategories
        .flatMap(el => el)
        .filter(sc => state.newProductSubcategories.map(c => c.id).includes(sc.id));

      // Populating product types here

      const allNewProductTypes: CategoryResponse[][] = [];

      newProductSubcategories.forEach(c => {
        allNewProductTypes.push(c.children);
      })

      const newProductTypes = allNewProductTypes
        .flatMap(el => el)
        .filter(sc => state.newProductTypes.map(c => c.id).includes(sc.id));

      // Populating product tags

      const allNewTags: TagStrippedResponse[][] = [];

      newProductTypes.forEach(c => {
        allNewTags.push(c.tags);
      })

      const newProductTags = allNewTags
        .flatMap(el => el)
        .filter(sc => state.newProductTags.map(c => c.id).includes(sc.id));

      console.log('new product types', newProductTags);

      return {
        ...state,
        allCategories: categories,
        newProductParentCategories: newProductParentCategories,
        newProductSubcategories: newProductSubcategories,
        newProductTypes: newProductTypes,
        newProductTags: newProductTags,
      }
    }),
    on(getCurrentSearchCategoryDetailsSuccess, (state, {category}) => {
      return {
        ...state,
        currentlySelectedCategory: category
      }
    }),
    on(selectParentCategoryForProductCreation, (state, {categoryId}) => {
      return {
        ...state,
        newProductParentCategories: state.newProductParentCategories.concat([state.allCategories.find(c => c.id === categoryId) || new CategoryResponse()])
      }
    }),
    on(loadNestedCategoriesMenuItemsSuccess, (state, {menuItems}) => {
      return {
        ...state,
        categoriesNestedMenuItems: menuItems
      }
    }),
    on(loadSelectableCategoriesMenuItemsSuccess, (state, {menuItems}) => {
      console.log('Selecting new parent category?');
      return {
        ...state,
        categoriesSelectableMenuItems: menuItems
      }
    }),
    on(unselectParentCategoryForProductCreation, (state, {categoryId}) => {
      const newProductParentCategories = state.newProductParentCategories.filter(c => c.id !== categoryId);
      const newProductSubcategories = state.newProductSubcategories.filter(s => newProductParentCategories.map(c => c.id).includes(s.parent.id));
      const newProductTypes = state.newProductTypes.filter(t => newProductSubcategories.map(s => s.id).includes(t.parent.id));

      return {
        ...state,
        newProductParentCategories: newProductParentCategories,
        newProductSubcategories: newProductSubcategories,
        newProductTypes: newProductTypes,
      }
    }),

    on(selectSubcategoryForProductCreation, (state, {category}) => {
      // Populating product types here

      const allNewProductTypes: CategoryResponse[][] = [];

      category.forEach(c => {
        allNewProductTypes.push(c.children);
      })

      const newProductTypes = allNewProductTypes
        .flatMap(el => el)
        .filter(sc => state.newProductTypes.map(c => c.id).includes(sc.id));

      // Populating product tags

      const allNewTags: TagStrippedResponse[][] = [];

      newProductTypes.forEach(c => {
        allNewTags.push(c.tags);
      })

      const newProductTags = allNewTags
        .flatMap(el => el)
        .filter(sc => state.newProductTags.map(c => c.id).includes(sc.id));

      return {
        ...state,
        newProductSubcategories: category,
        newProductTypes: newProductTypes,
        newProductTags: newProductTags
      }
    }),

    on(unselectSubcategoryForProductCreation, (state, {categoryId}) => {
      const newProductSubcategories = state.newProductSubcategories.filter(s => s.id !== categoryId);
      const newProductTypes = state.newProductTypes.filter(t => newProductSubcategories.map(s => s.id).includes(t.parent.id));

      return {
        ...state,
        newProductSubcategories: newProductSubcategories,
        newProductTypes: newProductTypes
      }
    }),

    on(selectProductTypeForProductCreation, (state, {category}) => {
      const allNewTags: TagStrippedResponse[][] = [];

      category.forEach(c => {
        allNewTags.push(c.tags);
      })

      const newProductTags = allNewTags
        .flatMap(el => el)
        .filter(sc => state.newProductTags.map(c => c.id).includes(sc.id));

      return {
        ...state,
        newProductTypes: category,
        newProductTags: newProductTags
      }
    }),

    on(unselectProductTypeForProductCreation, (state, {categoryId}) => {
      console.log('Unselecting product type');
      const newProductTypes = state.newProductTypes.filter(c => c.id !== categoryId);
      console.log('new product types', newProductTypes);

      return {
        ...state,
        newProductTypes: newProductTypes
      }
    }),

    on(selectTagsForProductCreation, (state, {tag}) => ({
      ...state,
      newProductTags: state.newProductTags.concat([tag])
    })),
    on(unselectTagsForProductCreation, (state, {tagId}) => ({
      ...state,
      newProductTags: state.newProductTags.filter(c => c.id !== tagId)
    })),
    on(setCurrentSearchWord, (state, {searchWord}) => {
      return {
        ...state,
        currentSearchWord: searchWord
      }
    }),

  )
})


export const {
  name: marketFeatureKey,
  reducer: marketReducer,
  selectAllCategories,
  selectNewProductParentCategories,
  selectNewProductTypes,
  selectNewProductSubcategories,
  selectNewProductTags,
  selectCategoriesSelectableMenuItems,
  selectCategoriesNestedMenuItems,
  selectCurrentlySelectedCategory,
  selectCurrentSearchWord
} = marketFeature;

