import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {RolesPreviewComponent} from "../../../../shared/components/roles-preview/roles-preview.component";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {LocationResponse} from "../../../../shared/models/location/location.response";
import {ProductClassComponent} from "../../../../shared/components/product-class/product-class.component";
import {PackagingResponse} from "../../packaging.response";
import {MarketPackagingsListComponent} from "../../market-packagings-list/market-packagings-list.component";

@Component({
  selector: 'bop-market-create-packaging',
  standalone: true,
  imports: [
    ButtonModule,
    RolesPreviewComponent,
    SharedModule,
    TableModule,
    ProductClassComponent,
    MarketPackagingsListComponent
  ],
  templateUrl: './market-create-packaging.component.html',
  styleUrl: './market-create-packaging.component.scss'
})
export class MarketCreatePackagingComponent {
  @Input() public packagings: PackagingResponse[] | undefined;
  @Input() public canCreatePackaging = false;

  @Output() public packagingAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() public packagingEdit: EventEmitter<LocationResponse> = new EventEmitter<any>();

  // getRolesIds(userRoles: RoleResponse[]): number[] {
  //   return userRoles.map(r => r.roleId);
  // }
}
