import {Injectable} from '@angular/core';
import {catchError, combineLatest, map, Observable, of, switchMap, take, tap, throwError} from 'rxjs';
import {PageableResponse} from "../../models/pageable/pageable.response";
import {CreatableDropdownService} from "./creatable-dropdown.service";
import {Store} from "@ngrx/store";

import {LocationService} from "../location.service";
import {MarketState} from "../../../private/market/store/market.state";
import {CategoryResponse} from "../../models/category.response";
import {CategoryRequest} from "../../models/category.request";
import {
  selectNewProductSubcategories,
  selectNewProductTypes
} from "../../../private/market/store/market.reducer";
import {TagStrippedResponse} from "../../models/tag-stripped.response";
import {TagCreateRequest} from "../../models/tag-create.request";
import {
  SelectNewCategoriesTagParentComponent
} from "../../components/select-categories-tags/select-new-categories-tag-parent/select-new-categories-tag-parent.component";
import {getAllCategories} from "../../../private/market/store/market.actions";
import {DialogService} from "primeng/dynamicdialog";
import {CategoriesService} from "../categories.service";
import {TagService} from "../tag.service";

@Injectable({providedIn: 'root'})
export class TagSelectionService implements CreatableDropdownService<TagStrippedResponse, TagCreateRequest> {
  constructor(private store: Store<MarketState>, private dialogService: DialogService, private tagService: TagService) {
  }

  newProductTypes$ = this.store.select(selectNewProductTypes);

  public getAll(): Observable<PageableResponse<TagStrippedResponse>> {
    return this.newProductTypes$.pipe(map(categories => {
      let childrenMatrix: TagStrippedResponse[][] = [];

      categories.forEach(c => childrenMatrix.push(c.tags));

      const children = childrenMatrix.flatMap(c => c);

      const page: PageableResponse<TagStrippedResponse> = PageableResponse.empty();
      page.content = children;

      return page;
    }))
  }

  public create(request: TagCreateRequest): Observable<TagStrippedResponse> {
    return this.newProductTypes$.pipe(
      map(categories => {
        const newCategories = categories.slice();
        return this.dialogService.open(SelectNewCategoriesTagParentComponent, {
          header: `Моля, изберете родителска категория за "${request.name}"`,
          data: {
            possibleParents: newCategories
          }
        });
      }),
      switchMap(dialogRef =>
        dialogRef.onClose.pipe(
          switchMap((result: string | undefined) => {
            if (!result) {
              throw new Error('No parent category selected');
            }
            const tagRequest = new TagCreateRequest(request.name, [+result]);
            console.log(tagRequest);
            return this.tagService.create(tagRequest).pipe(
              tap((response: TagStrippedResponse) => {
                this.store.dispatch(getAllCategories())
              })
            );
          })
        )
      ),
      catchError(err => {
        console.error('Error during category creation:', err);
        return throwError(() => new Error('Category creation failed'));
      })
    );
  }
}
