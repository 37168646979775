import {Component, OnInit} from '@angular/core';
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputTextModule} from "primeng/inputtext";
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {ButtonModule} from "primeng/button";
import {AddressCardComponent} from "./profile-edit-addresses/address-card/address-card.component";
import {CheckboxModule} from "primeng/checkbox";
import {TabViewModule} from "primeng/tabview";
import {ProfileEditDataComponent} from "./profile-edit-data/profile-edit-data.component";
import {ProfileEditAddressesComponent} from "./profile-edit-addresses/profile-edit-addresses.component";
import {ProfileEditRolesComponent} from "./profile-edit-roles/profile-edit-roles.component";
import {ProfileEditWishesComponent} from "./profile-edit-wishes/profile-edit-wishes.component";
import {Store} from "@ngrx/store";
import {ProfileState} from "../store/profile.state";
import {
  selectCurrentUser,
  selectGetProfileLoading,
  selectSelectedAddresses,
  selectSelectedWishes,
  selectUpdateProfileLoading
} from "../store/profile.reducer";
import {
  getInvitedProfile,
  getMyProfile,
  setSelectedAddress,
  setSelectedRole,
  setSelectedWishes,
  updateMyProfile
} from "../store/profile.actions";
import {PhoneNumberResponse, ProfileResponse, Role} from "../profile.response";
import {AsyncPipe} from "@angular/common";
import {Observable} from "rxjs";
import {availableRoles} from "../profile.config";
import {DialogService} from "primeng/dynamicdialog";
import {AddressFormComponent} from "./profile-edit-addresses/address-form/address-form.component";
import {WishPackage} from "./profile-edit-wishes/wish.package";
import {LocationResponse} from "../../../shared/models/location/location.response";
import {LocationRequest} from "../../../shared/models/location/location.request";
import {ProfileEditPrivacyComponent} from "./profile-edit-privacy/profile-edit-privacy.component";
import {PhoneNumberRequest, ProfileRequest, RoleRequest} from "../profile.request";
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {PhoneNumberValidator} from "../../../shared/validators/phone-number.validator";
import {MatchingFieldsValidator} from "../../../shared/validators/matching-fields.validator";
import {ActivatedRoute} from "@angular/router";
import {FileResponse} from "../../../shared/models/file.response";
import {ProfileEditLogoutTimeComponent} from "./profile-edit-logout-time/profile-edit-logout-time.component";
import {SpinnerOverlay} from "../../../shared/components/spinner/spinner-overlay";
import {
  ProfileEditNotificationPeriodComponent
} from "./profile-edit-notification-period/profile-edit-notification-period.component";
import {LocationService} from "../../../shared/services/location.service";

@Component({
  selector: 'bop-profile-edit',
  standalone: true,
  imports: [
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    FormsModule,
    PasswordModule,
    ReactiveFormsModule,
    DividerModule,
    ButtonModule,
    AddressCardComponent,
    CheckboxModule,
    TabViewModule,
    ProfileEditDataComponent,
    ProfileEditAddressesComponent,
    ProfileEditRolesComponent,
    ProfileEditWishesComponent,
    AsyncPipe,
    ProfileEditPrivacyComponent,
    FormWrapperComponent,
    ProfileEditLogoutTimeComponent,
    SpinnerOverlay,
    ProfileEditNotificationPeriodComponent
  ],
  providers: [DialogService],
  templateUrl: './profile-edit.component.html',
  styleUrl: './profile-edit.component.scss'
})
export class ProfileEditComponent implements OnInit {
  public lightRegistrationMode = false;
  public lightRegistrationToken = '';

  public profile$: Observable<ProfileResponse | null> = this.store.select(selectCurrentUser);
  public selectedWishes$: Observable<WishPackage | null> = this.store.select(selectSelectedWishes);
  public selectedAddresses$: Observable<LocationResponse[] | null> = this.store.select(selectSelectedAddresses);
  protected readonly availableRoles = availableRoles;
  public initiallySelectedRoles: any = {};
  public initialPhoneNumbers: PhoneNumberResponse[] = [];

  public initialDataLoading = false;
  public savedLoading = false;

  public profileDataForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, [Validators.required, Validators.pattern('^[^\\d]*$')]),
    middleName: new FormControl(null, [Validators.pattern('^[^\\d]*$')]),
    lastName: new FormControl(null, [Validators.required, Validators.pattern('^[^\\d]*$')]),
    password: new FormControl(null),
    confirmPassword: new FormControl(null),
    email: new FormControl(null, [Validators.email]),
    phoneNumbers: new FormArray([
      new FormControl('', PhoneNumberValidator('BG')),
      new FormControl('', PhoneNumberValidator('BG', true))
    ]),
    roles: new FormArray([]),
    locations: new FormArray([]),
    bopSoftware: new FormControl(['', '', '', '', '']),
    wantToSell: new FormControl(['', '', '', '', '']),
    wantToBuy: new FormControl(['', '', '', '', '']),
    wantToExchange: new FormControl(['', '', '', '', '']),
    publicProfile: new FormControl(false),
    logoutPeriod: new FormControl(null),
    profileNotificationType: new FormControl(1),
  }, {validators: MatchingFieldsValidator('password', 'confirmPassword')});

  constructor(private store: Store<ProfileState>,
              private dialog: DialogService,
              private locationService: LocationService,
              private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    if (this.route.snapshot.params['token']) {
      this.lightRegistrationMode = true;
      this.lightRegistrationToken = this.route.snapshot.params['token']
      this.store.dispatch(getInvitedProfile({token: this.lightRegistrationToken}))
    } else {
      this.store.dispatch(getMyProfile());
    }

    availableRoles.forEach(r => {
      this.initiallySelectedRoles[r.id] = false;
    })


    this.profile$.subscribe(profileResponse => {
      this.populateDataFormFields(profileResponse || new ProfileResponse());
      this.populateInitialWishes(profileResponse || new ProfileResponse());
      this.initialPhoneNumbers = profileResponse?.phoneNumbers || [];
    })

    this.selectedWishes$.subscribe(wishes => {
      this.setWishValues(wishes || new WishPackage());
    })

    this.selectedAddresses$.subscribe(addresses => {
      this.setAddressesValues(addresses || []);
    })

    this.store.select(selectGetProfileLoading).subscribe(loading => this.initialDataLoading = loading);
    this.store.select(selectUpdateProfileLoading).subscribe(loading => this.savedLoading = loading);
  }

  public populateDataFormFields(profile: ProfileResponse) {
    this.profileDataForm.patchValue(
      {
        firstName: profile?.firstName,
        middleName: profile?.middleName,
        lastName: profile?.lastName,
        publicProfile: profile?.publicProfile,
        email: profile.email,
        bopSoftware: this.toWishArray(profile.bopSoftware),
        wantToBuy: '',
        wantToSell: '',
        wantToExchange: '',
        logoutPeriod: profile.logoutPeriod,
        profileNotificationType: profile.profileNotificationType
      }
    )

    profile.phoneNumbers.forEach((phoneNumber, index) => {
      (this.profileDataForm.get('phoneNumbers') as FormArray).at(index).setValue(phoneNumber.number)
    })

    profile.userRoles.forEach(role => {
      (this.profileDataForm.get('roles') as FormArray).push(new FormControl({
        roleId: role.roleId,
        reason: role.reason
      }))

      this.store.dispatch(setSelectedRole({role: new Role(role.roleId, this.availableRoles.filter(r => r.id === role.roleId)[0].name)}));
      this.initiallySelectedRoles[role.roleId] = true;

    })

    profile.locations.forEach(location => {
      (this.profileDataForm.get('locations') as FormArray).push(new FormControl({
        address: location.address,
        comment: location.comment,
        settlement: location.settlement,
        locationRoles: location.locationRoles
      }))
    })

  }

  populateInitialWishes(profile: ProfileResponse) {
    const wishPackage = new WishPackage();

    profile.wantToExchange.split('*').filter(w => w !== '').forEach((value, index) => {
      wishPackage.wantToExchange[index] = {id: index + 1, name: value, isDraft: false};
    })

    profile.wantToBuy.split('*').filter(w => w !== '').forEach((value, index) => {
      wishPackage.wantToBuy[index] = {id: index + 1, name: value, isDraft: false};
    })

    profile.wantToSell.split('*').filter(w => w !== '').forEach((value, index) => {
      wishPackage.wantToSell[index] = {id: index + 1, name: value, isDraft: false};
    })

    profile.bopSoftware.split('*').filter(w => w !== '').forEach((value, index) => {
      wishPackage.bopSoftware[index] = {id: index + 1, name: value, isDraft: false};
    })

    this.store.dispatch(setSelectedWishes({wishes: wishPackage}))
  }

  setWishValues(wishPackage: WishPackage) {
    this.profileDataForm.get('bopSoftware')?.setValue(`*${wishPackage.bopSoftware.map(w => w.name).join('*')}*`);
    this.profileDataForm.get('wantToBuy')?.setValue(`*${wishPackage.wantToBuy.map(w => w.name).join('*')}*`);
    this.profileDataForm.get('wantToSell')?.setValue(`*${wishPackage.wantToSell.map(w => w.name).join('*')}*`);
    this.profileDataForm.get('wantToExchange')?.setValue(`*${wishPackage.wantToExchange.map(w => w.name).join('*')}*`);
  }

  setAddressesValues(addresses: LocationResponse[]) {
    (this.profileDataForm.get('locations') as FormArray).clear();

    addresses.forEach(address => {
      (this.profileDataForm.get('locations') as FormArray).push(new FormControl(
        new LocationRequest(address?.id, address.address, address.comment, address.coordinates, address.settlement?.id, address.residentialArea?.id, address.locationRoles.map(r => new RoleRequest(r.roleId, r.reason)), address.locationImage?.id)
      ));
    })
  }

  openAddressFormModal(address: LocationResponse | null = null) {
    this.locationService.createEditLocation(address).subscribe(() => this.store.dispatch(getMyProfile()))
  }


  toWishArray(wish: string) {
    const array = ['', '', '', '', ''];
    wish.split('*').filter(w => w !== '').forEach((value, index) => array[index] = value);

    return array;
  }

  saveProfileData() {
    let request: ProfileRequest = Object.assign(new ProfileRequest(), this.profileDataForm.value);

    request.phoneNumbers = this.profileDataForm.value.phoneNumbers.map((n: string) => {
      let phoneNumberId = null;
      let originalPhoneNumber = this.initialPhoneNumbers.filter(number => number.number === n);
      if (originalPhoneNumber.length > 0) {
        phoneNumberId = originalPhoneNumber[0].id;
      }
      return new PhoneNumberRequest(phoneNumberId, n)
    });

    if (!this.lightRegistrationMode) {
      console.log('profile update profile request: ', request);
      this.store.dispatch(updateMyProfile({request: request}));
      return;
    }

    console.log('profile update profile request: ', request);
    // this.store.dispatch(finishInvitedProfile({request, token: this.lightRegistrationToken}));
  }
}
