import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {FileResponse} from "../models/file.response";
import {CategoryResponse} from "../models/category.response";
import {PageableResponse} from "../models/pageable/pageable.response";
import {Filtration, InvokableFiltration} from "../models/filtration/filtration";
import {PageableRequest} from "../models/pageable/pageable.request";
import {QueryFormattingUtility} from "../models/filtration/query-formatting.utility";
import {CreatableDropdownService} from "./creatable-dropdown-service/creatable-dropdown.service";
import {CategoryRequest} from "../models/category.request";
import {ProductMenuElement} from "../models/product-menu-element";
import {MenuItem, MenuItemCommandEvent} from "primeng/api";
import {Store} from "@ngrx/store";
import {MarketState} from "../../private/market/store/market.state";
import {getCurrentSearchCategoryDetails} from "../../private/market/store/market.actions";

@Injectable({providedIn: 'root'})
export class CategoriesService implements CreatableDropdownService<CategoryResponse, CategoryRequest> {
  constructor(private http: HttpClient,
              private queryFormattingUtility: QueryFormattingUtility,
              private store: Store<MarketState>
  ) {
  }

  getAll(filtration: InvokableFiltration = Filtration.empty(), page: PageableRequest = PageableRequest.first()): Observable<PageableResponse<CategoryResponse>> {
    return this.http.get<PageableResponse<CategoryResponse>>('api/categories', {params: this.queryFormattingUtility.formatQuery(page, filtration)});
  }

  getOne(id: number): Observable<CategoryResponse> {
    return this.http.get<CategoryResponse>('api/categories/' + id);
  }

  create(request: CategoryRequest): Observable<CategoryResponse> {
    return this.http.post<CategoryResponse>('api/categories', request);
  }

  mapCategoriesToMenuItems(categories: ProductMenuElement[]): MenuItem[] {
    return categories
      .map(c => ({label: `${c.id} @ ${c.name}`}));
  }

  mapCategoriesToNestedMenuItems(categories: ProductMenuElement[]): MenuItem[] {
    return categories
      .map(c => ({
        label: `"${c.id} @ ${c.name}`,
        command: (event: MenuItemCommandEvent) => {
          console.log(event);
          this.store.dispatch(getCurrentSearchCategoryDetails({categoryId: +c.id}))
        },
        items: this.mapCategoriesToNestedMenuItems(c.children?.length ? c.children : c.tags || [])
      }))
  }
}
