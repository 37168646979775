import {Injectable} from "@angular/core";
import {CreatableDropdownService} from "./creatable-dropdown.service";
import {MeasurementResponse} from "../../models/measurement.response";
import {MeasurementRequest} from "../../models/measurement.request";
import {HttpClient} from "@angular/common/http";
import {QueryFormattingUtility} from "../../models/filtration/query-formatting.utility";
import {Filtration, InvokableFiltration} from "../../models/filtration/filtration";
import {PageableRequest} from "../../models/pageable/pageable.request";
import {Observable} from "rxjs";
import {PageableResponse} from "../../models/pageable/pageable.response";
import {NamedElement} from "../../models/named-element";

@Injectable({providedIn: 'root'})
export class PackagingTypeService implements CreatableDropdownService<NamedElement, NamedElement> {
  constructor(
    private http: HttpClient,
    private queryFormattingUtility: QueryFormattingUtility
  ) {
  }

  public getAll(
    filtration: InvokableFiltration = Filtration.empty(), page: PageableRequest = PageableRequest.first()
  ): Observable<PageableResponse<NamedElement>> {
    const params = this.queryFormattingUtility.formatQuery(page, filtration);
    return this.http.get<PageableResponse<MeasurementResponse>>('api/packaging-types', {params});
  }

  public create(request: NamedElement): Observable<MeasurementResponse> {
    return this.http.post<MeasurementResponse>(`api/packaging-types`, request);
  }
}
