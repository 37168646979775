import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PageableRequest} from '../../shared/models/pageable/pageable.request';
import {PageableResponse} from '../../shared/models/pageable/pageable.response';
import {QueryFormattingUtility} from '../../shared/models/filtration/query-formatting.utility';
import {ProductFlatResponse} from "./product-flat.response";
import {ProductResponse} from "./product.response";
import {CreateProductRequest} from "./product-create.request";
import {UpdateProductRequest} from "./product-update.request";
import {CreatePackagingRequest} from "./packaging-create.request";
import {UpdatePackagingRequest} from "./packaging-update.request";
import {PackagingResponse} from "./packaging.response";


@Injectable({providedIn: 'root'})
export class MarketService {
  constructor(
    private http: HttpClient,
    private queryFormattingUtility: QueryFormattingUtility
  ) {
  }

  public getProducts(
    pageable: PageableRequest,
    filtration: any
  ): Observable<PageableResponse<ProductFlatResponse>> {
    const params = this.queryFormattingUtility.formatQuery(pageable, filtration);
    return this.http.get<PageableResponse<ProductFlatResponse>>('api/products', {params});
  }

  public getProduct(id: number): Observable<ProductResponse> {
    return this.http.get<ProductResponse>(`api/products/${id}`);
  }

  public createProduct(
    request: CreateProductRequest
  ): Observable<ProductResponse> {
    return this.http.post<ProductResponse>('api/products', request);
  }

  public updateProduct(
    id: number,
    request: UpdateProductRequest
  ): Observable<ProductResponse> {
    return this.http.patch<ProductResponse>(`api/products/${id}`, request);
  }

  public addPackaging(
    productId: number,
    request: CreatePackagingRequest
  ): Observable<ProductResponse> {
    return this.http.post<ProductResponse>(
      `api/products/${productId}/packagings`,
      request
    );
  }

  public updatePackaging(
    productId: number,
    packageId: number,
    request: UpdatePackagingRequest
  ): Observable<ProductResponse> {
    return this.http.patch<ProductResponse>(
      `api/products/${productId}/packagings/${packageId}`,
      request
    );
  }

  public deleteProduct(id: number): Observable<void> {
    return this.http.delete<void>(`api/products/${id}`);
  }
}
