import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {LayoutStore} from "../../../shared/store/layout/layout.store";
import {MegaMenuItem, MenuItem} from "primeng/api";
import {CategoriesService} from "../../../shared/services/categories.service";
import {CategoryResponse} from "../../../shared/models/category.response";
import {TagStrippedResponse} from "../../../shared/models/tag-stripped.response";
import {ProductMenuElement} from "../../../shared/models/product-menu-element";
import {Filtration} from "../../../shared/models/filtration/filtration";
import {PageableRequest} from "../../../shared/models/pageable/pageable.request";
import {SortEventRequest} from "../../../shared/models/filtration/sort-event.request";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {MarketBaseComponent} from "../market-base/market-base.component";
import {isMobile} from "../../../shared/helpers/device-type.helper";
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {Store} from "@ngrx/store";
import {MarketState} from "../store/market.state";
import {
  selectCategoriesNestedMenuItems,
  selectCurrentlySelectedCategory,
  selectCurrentSearchWord
} from "../store/market.reducer";
import {loadNestedCategoriesMenuItems} from "../store/market.actions";
import {MarketBreadcrumbComponent} from "../market-breadcrumb/market-breadcrumb.component";
import {AsyncPipe} from "@angular/common";
import {combineLatest, distinctUntilChanged, filter, map, Observable, switchMap} from "rxjs";
import {MarketService} from "../market.service";
import {ProductResponse} from "../product.response";
import {ProductFlatResponse} from "../product-flat.response";
import {FileUrlPipe} from "../../../shared/pipes/file-url.pipe";

@Component({
  selector: 'bop-products',
  standalone: true,
  imports: [
    BreadcrumbModule,
    CardModule,
    ButtonModule,
    FormWrapperComponent,
    MarketBreadcrumbComponent,
    AsyncPipe,
    FileUrlPipe
  ],
  templateUrl: './market-products.component.html',
  styleUrl: './market-products.component.scss'
})
export class MarketProductsComponent extends MarketBaseComponent implements OnInit {
  public marketStore = inject(Store<MarketState>);
  public marketService = inject(MarketService);

  public currentSelectedCategory$: Observable<CategoryResponse | null> = this.marketStore.select(selectCurrentlySelectedCategory);
  public currentSearchWord$: Observable<string> = this.marketStore.select(selectCurrentSearchWord);
  public categoriesNestedMenuItems$: Observable<MenuItem[]> = this.marketStore.select(selectCategoriesNestedMenuItems);

  public loadedProducts: ProductFlatResponse[] = [];

  ngOnInit() {
    this.marketStore.dispatch(loadNestedCategoriesMenuItems())

    this.layoutStore.setSidebarExpanded(true);
    this.layoutStore.setSidebarPanelMenuItems([]);
    this.layoutStore.setSidebarTieredMenuItems([]);
    this.layoutStore.setSidebarSearchBar(true);

    this.categoriesNestedMenuItems$.subscribe(items => {
      if (isMobile()) {
        this.layoutStore.setSidebarPanelMenuItems(items);
        return;
      }
      this.layoutStore.setSidebarTieredMenuItems(items);
    });

    combineLatest([this.currentSelectedCategory$, this.currentSearchWord$])
      .pipe(
        filter(([category, searchWord]) => category != null || searchWord != null), // Ensure at least one is not null
        distinctUntilChanged(),
        switchMap(([category, searchWord]) => {
          console.log(category);
          console.log(searchWord);

          // Dynamically build filtration based on availability
          const filtration = new Filtration().field('categories.id').contains(category?.id).and('name').contains(searchWord);
          // }
          // if (searchWord) {
          // }

          console.log('filtration', filtration);

          return this.marketService.getProducts(PageableRequest.first(), filtration);
        }),
        map(productsRes =>
          productsRes.content.filter((product, index, self) =>
            index === self.findIndex(p => p.id === product.id)
          )
        )
      )
      .subscribe(filteredProducts => {
        this.loadedProducts = filteredProducts;
      });
  }
}
