import {DistrictResponse} from "./district.response";

export class ResidentialAreaResponse {
  constructor(
    public id: number = 0,
    public name: string = ''
  ) {

  }
}
