import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {PageableResponse} from "../../models/pageable/pageable.response";
import {CreatableDropdownService} from "./creatable-dropdown.service";
import {NamedElement} from "../../models/named-element";

@Injectable({providedIn: 'root'})
export class QualityService implements CreatableDropdownService<NamedElement, NamedElement> {

  public qualities: NamedElement[] = [
    {id: 1, name: 'Първо'},
    {id: 2, name: 'Второ'},
    {id: 3, name: 'Трето'}
  ];

  public getAll(): Observable<PageableResponse<NamedElement>> {
    const response: PageableResponse<NamedElement> = PageableResponse.empty();
    response.content = this.qualities;
    return of(response);
  }

  public create(request: NamedElement): Observable<NamedElement> {
    return of(new NamedElement())
  }
}
