import {Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {ProductMenuElement} from "../../../shared/models/product-menu-element";
import {MenuItem, MessageService} from "primeng/api";
import {CategoriesService} from "../../../shared/services/categories.service";
import {MarketBaseComponent} from "../market-base/market-base.component";
import {FormWrapperComponent} from "../../../shared/components/form-wrapper/form-wrapper.component";
import {
  FormRowWrapperComponent
} from "../../../shared/components/form-wrapper/form-row-wrapper/form-row-wrapper.component";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputTextModule} from "primeng/inputtext";
import {ReactiveFormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {AsyncPipe} from "@angular/common";
import {AutoCompleteModule} from "primeng/autocomplete";
import {MarketCreateProductFormComponent} from "./market-create-product-form/market-create-product-form.component";
import {MarketCreatePackagingComponent} from "./market-create-packaging/market-create-packaging.component";
import {DialogService} from "primeng/dynamicdialog";
import {
  MarketCreatePackagingFormComponent
} from "./market-create-packaging/market-create-packaging-form/market-create-packaging-form.component";
import {Store} from "@ngrx/store";
import {loadSelectableCategoriesMenuItems} from "../store/market.actions";
import {TabViewModule} from "primeng/tabview";
import {MarketService} from "../market.service";
import {CreateProductRequest} from "../product-create.request";
import {filter} from "rxjs";
import {CreatePackagingRequest} from "../packaging-create.request";
import {PackagingResponse} from "../packaging.response";
import {selectCategoriesSelectableMenuItems, selectCurrentlySelectedCategory} from "../store/market.reducer";

@Component({
  selector: 'bop-market-create',
  standalone: true,
  imports: [
    RouterOutlet,
    FormWrapperComponent,
    FormRowWrapperComponent,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    AsyncPipe,
    AutoCompleteModule,
    MarketCreateProductFormComponent,
    MarketCreatePackagingComponent,
    TabViewModule
  ],
  templateUrl: './market-create.component.html',
  styleUrl: './market-create.component.scss'
})
export class MarketCreateComponent extends MarketBaseComponent implements OnInit {
  public dialog = inject(DialogService);
  public messageService = inject(MessageService);

  public items: MenuItem[] | undefined;
  public home: MenuItem | undefined;
  public currentProductId: number | undefined;

  public packagings: PackagingResponse[] = [];

  private marketStore = inject(Store);
  private marketService = inject(MarketService);

  ngOnInit() {
    this.marketStore.dispatch(loadSelectableCategoriesMenuItems())
    this.layoutStore.resetSidebar();
    this.layoutStore.setSidebarExpanded(true);
    this.layoutStore.setSidebarText('Въведете вашите български:');
    this.layoutStore.setTieredMenuSelectable(true);

    this.marketStore.select(selectCategoriesSelectableMenuItems).subscribe(items => {
      this.layoutStore.setSidebarTieredMenuItems(items);
    });
  }

  openPackagingModal() {
    this.dialog.open(MarketCreatePackagingFormComponent,
      {
        header: 'Разфасофки',
      }
    ).onClose.pipe(filter(result => !!result))
      .subscribe((response: CreatePackagingRequest) => {
        if (!this.currentProductId) {
          return;
        }

        this.marketService.addPackaging(this.currentProductId, response)
          .subscribe(packagingResponse => {
            this.messageService.add({severity: 'success', summary: 'Успешно добавена разфасовка!'});
            this.packagings = Array.from(packagingResponse.packagings);
          })
      })
  }

  saveProduct($event: CreateProductRequest) {
    this.marketService.createProduct($event).subscribe(newProduct => {
      this.messageService.add({severity: 'success', summary: 'Успешно запазване на продукт!'});
      this.currentProductId = newProduct.id;
    })
  }
}
