import {Injectable, Pipe, PipeTransform} from "@angular/core";

export const categoryMenuItemDelimiter = '@';

@Pipe({
  name: 'categoryMenuItemName',
  standalone: true
})
export class CategoryMenuItemName implements PipeTransform {
  transform(value: string): string {
    return value.split(categoryMenuItemDelimiter)[1].trim();
  }
}

@Pipe({
  name: 'categoryMenuItemId',
  standalone: true
})
@Injectable({providedIn: "root"})
export class CategoryMenuItemId implements PipeTransform {
  transform(value: string): string {
    return value.split(categoryMenuItemDelimiter)[0].trim();
  }
}
