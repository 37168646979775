import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MeasurementResponse} from "../../models/measurement.response";
import {QueryFormattingUtility} from "../../models/filtration/query-formatting.utility";
import {PageableRequest} from "../../models/pageable/pageable.request";
import {PageableResponse} from "../../models/pageable/pageable.response";
import {Filtration, InvokableFiltration} from "../../models/filtration/filtration";
import {MeasurementRequest} from "../../models/measurement.request";
import {CreatableDropdownService} from "./creatable-dropdown.service";

@Injectable({providedIn: 'root'})
export class MeasurementService implements CreatableDropdownService<MeasurementResponse, MeasurementRequest> {
  constructor(
    private http: HttpClient,
    private queryFormattingUtility: QueryFormattingUtility
  ) {
  }

  public getAll(
    filtration: InvokableFiltration = Filtration.empty(), page: PageableRequest = PageableRequest.first()
  ): Observable<PageableResponse<MeasurementResponse>> {
    const params = this.queryFormattingUtility.formatQuery(page, filtration);
    return this.http.get<PageableResponse<MeasurementResponse>>('api/measurements', {params});
  }

  public create(request: MeasurementRequest): Observable<MeasurementResponse> {
    return this.http.post<MeasurementResponse>(`api/measurements`, request);
  }
}
