import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {AutoComplete, AutoCompleteModule, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {distinctUntilChanged, filter, map, Observable, take, tap} from "rxjs";
import {MunicipalityResponse} from "../../models/municipality.response";
import {SettlementResponse} from "../../models/settlement.response";
import {Store} from "@ngrx/store";
import {LocationState} from "../../store/location/location.state";
import {getMunicipalities, getResidentialAreas, getSettlements} from "../../store/location/location.actions";
import {DistrictResponse} from "../../models/district.response";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {selectMunicipalities, selectResidentialAreas, selectSettlements} from "../../store/location/location.reducer";
import {lat2cyr} from "../../services/transliteration.service";
import {ResidentialAreaResponse} from "../../models/residential-area.response";
import {FormRowWrapperComponent} from "../form-wrapper/form-row-wrapper/form-row-wrapper.component";

@Component({
  selector: 'bop-settlement-selection',
  standalone: true,
  imports: [
    AsyncPipe,
    AutoCompleteModule,
    InputGroupAddonModule,
    InputGroupModule,
    ReactiveFormsModule,
    NgIf,
    FormRowWrapperComponent
  ],
  templateUrl: './settlement-selection.component.html',
  styleUrl: './settlement-selection.component.scss'
})
export class SettlementSelectionComponent {
  @Output()
  public onSettlementSelected: EventEmitter<SettlementResponse> = new EventEmitter<SettlementResponse>();

  @Output()
  public onResidentialAreaSelected: EventEmitter<ResidentialAreaResponse> = new EventEmitter<ResidentialAreaResponse>();

  @Input()
  public municipalityName: string = '';

  @Input()
  public settlementName: string = '';

  @Input()
  public residentialAreaName: string = '';

  public municipalitySearchControl = new FormControl(this.municipalityName);
  public settlementSearchControl = new FormControl(this.settlementName);
  public residentialAreaSearchControl = new FormControl(this.residentialAreaName);
  public settlementHasResidentialAreas = false;

  public municipalities$: Observable<MunicipalityResponse[]> =
    this.locationStore.select(selectMunicipalities).pipe(map(page => page.content!));

  public settlements$: Observable<SettlementResponse[]> =
    this.locationStore.select(selectSettlements).pipe(map(page => page.content!), tap(() => {
      this.municipalityField?.inputEL?.nativeElement.blur();
      this.settlementField?.inputEL?.nativeElement.focus();
    }));

  public residentialAreas$: Observable<ResidentialAreaResponse[]> = this.locationStore.select(selectResidentialAreas)
    .pipe(map(page => page.content!));

  public selectedMunicipality: MunicipalityResponse | undefined;
  public selectedSettlement: SettlementResponse | undefined;
  public selectedResidentialArea: SettlementResponse | undefined;
  private municipalityField: AutoComplete | undefined;
  private settlementField: AutoComplete | undefined;
  private residentialAreaField: AutoComplete | undefined;
  public hasResidentialAreas = false;

  constructor(
    private locationStore: Store<LocationState>
  ) {
  }

  ngOnChanges() {
    this.municipalitySearchControl.setValue(lat2cyr(this.municipalityName));
    this.settlementSearchControl.setValue(lat2cyr(this.settlementName));
    this.residentialAreaSearchControl.setValue(lat2cyr(this.residentialAreaName));
  }

  ngOnInit() {
    this.initMunicipalities('');
    this.initSettlements('')
    this.initResidentialAreas('');

    this.settlementSearchControl.disable();
    this.residentialAreaSearchControl.disable();

    this.municipalitySearchControl.valueChanges.pipe(
      distinctUntilChanged(),
      filter(value => !!value),
      map(value => lat2cyr(value!)),
    ).subscribe(request => this.locationStore.dispatch(getMunicipalities({request})));

    this.settlementSearchControl.valueChanges.pipe(
      distinctUntilChanged(),
      filter(value => !!value),
      map(value => lat2cyr(value!)),
    ).subscribe(namePrefix => this.initSettlements(namePrefix));

    this.residentialAreaSearchControl.valueChanges.pipe(
      distinctUntilChanged(),
      filter(value => !!value),
      map(value => lat2cyr(value!)),
    ).subscribe(namePrefix => this.initResidentialAreas(namePrefix));

    this.residentialAreas$.subscribe(areas => {
      if (!this.residentialAreaSearchControl.value) {
        this.settlementHasResidentialAreas = areas.length > 0;
        console.log('Setting canHaveResidentialAreas:', this.settlementHasResidentialAreas);
      }
    });
  }

  onMunicipalitySelect($event: AutoCompleteSelectEvent) {
    this.selectedMunicipality = $event.value;
    this.selectedSettlement = undefined;
    this.selectedResidentialArea = undefined;

    this.settlementSearchControl.setValue('');
    this.residentialAreaSearchControl.setValue('');

    this.settlementSearchControl.enable();
    this.residentialAreaSearchControl.disable();

    this.initSettlements('');
    this.settlementHasResidentialAreas = false;
  }

  onSettlementSelect($event: AutoCompleteSelectEvent) {
    this.selectedSettlement = $event.value;
    this.selectedResidentialArea = undefined;
    this.residentialAreaSearchControl.setValue('');

    this.onSettlementSelected.emit(this.selectedSettlement);
    this.residentialAreaSearchControl.enable();
    this.initResidentialAreas('');
  }

  onResidentialAreaSelect($event: AutoCompleteSelectEvent) {
    this.selectedResidentialArea = $event.value;
    this.onResidentialAreaSelected.emit(this.selectedResidentialArea);
  }

  private initMunicipalities(request: string) {
    this.locationStore.dispatch(getMunicipalities({request}))
  }

  private initSettlements(namePrefix: string) {
    this.locationStore.dispatch(
      getSettlements(
        {
          request: {
            municipality: this.selectedMunicipality || new MunicipalityResponse(1, '', new DistrictResponse(1, '')),
            namePrefix
          }
        })
    )
  }

  private initResidentialAreas(namePrefix: string) {
    this.locationStore.dispatch(
      getResidentialAreas(
        {
          request: {
            settlement: this.selectedSettlement || new SettlementResponse(1, '', new MunicipalityResponse(1, '')),
            namePrefix
          }
        })
    )
  }

  assignAutocompleteFields(municipalityField: AutoComplete, settlementField: AutoComplete, residentialAreaField?: AutoComplete | null) {
    this.municipalityField = municipalityField;
    this.settlementField = settlementField;
    this.residentialAreaField = residentialAreaField  || undefined;
  }
}
