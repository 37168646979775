import {Component, inject} from '@angular/core';
import {CarouselModule} from "primeng/carousel";
import {ProductResponse} from "../../product.response";
import {MarketPackagingsListComponent} from "../../market-packagings-list/market-packagings-list.component";
import {ActivatedRoute, Params} from "@angular/router";
import {MarketService} from "../../market.service";
import {distinctUntilChanged} from "rxjs";
import {CardModule} from "primeng/card";
import {ChipsModule} from "primeng/chips";
import {AccordionModule} from "primeng/accordion";
import {FormsModule} from "@angular/forms";
import {NamedElement} from "../../../../shared/models/named-element";
import {FormWrapperComponent} from "../../../../shared/components/form-wrapper/form-wrapper.component";
import {GalleriaModule} from "primeng/galleria";
import {
  MarketCreatePackagingComponent
} from "../../market-create/market-create-packaging/market-create-packaging.component";
import {
  MarketCreateProductFormComponent
} from "../../market-create/market-create-product-form/market-create-product-form.component";
import {TabViewModule} from "primeng/tabview";
import {RichEditorComponent} from "../../../../shared/components/rich-editor/rich-editor.component";
import {FileUrlPipe} from "../../../../shared/pipes/file-url.pipe";

@Component({
  selector: 'bop-market-product-details',
  standalone: true,
  imports: [
    CarouselModule,
    MarketPackagingsListComponent,
    CardModule,
    ChipsModule,
    AccordionModule,
    FormsModule,
    FormWrapperComponent,
    GalleriaModule,
    MarketCreatePackagingComponent,
    MarketCreateProductFormComponent,
    TabViewModule,
    RichEditorComponent,
    FileUrlPipe
  ],
  templateUrl: './market-product-details.component.html',
  styleUrl: './market-product-details.component.scss'
})
export class MarketProductDetailsComponent {
  public activatedRoute = inject(ActivatedRoute);
  public product: ProductResponse = new ProductResponse();
  public marketService = inject(MarketService);

  public categoriesNames: string[] = [];
  public tagsNames: string[] = [];

  ngOnInit() {
    this.activatedRoute.params.pipe(distinctUntilChanged()).subscribe((params: Params) => {
      this.marketService.getProduct(+params['id']).subscribe(product => {
        console.log(product);
        this.product = product;

        this.categoriesNames = product.categories.map(c => c.name);
        this.tagsNames = product.tags.map(t => t.name);
      })
    })
  }
}
