<section>
  <p-table
    #table
    selectionMode="single"
    [value]="grid.pagedData.content"
    dataKey="id"
    [totalRecords]="grid.pagedData.totalElements"
    [scrollable]="true"
    scrollHeight="auto"
    [rows]="grid.pageSize()"
    [first]="grid.firstPageElement()"
    [filters]="grid.filters"
    [sortField]="grid.sortField()"
    [sortOrder]="grid.sortDirection()"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [paginator]="true"
    [lazy]="true"
    (onLazyLoad)="grid.loadData($event)"
    [metaKeySelection]="false"
    [tableStyle]="{'max-width': '6rem'}"
    currentPageReportTemplate="Показани от {first} до {last} от общо {totalRecords} записа"
  >
    <ng-template pTemplate="caption">
      <section class="flex gap-2">
        <p-button
          [outlined]="false"
          label="Запази в имейл"
          icon="pi pi-envelope"
          (click)="saveAs('email');"
          size="small">

        </p-button>

        <!--        <p-button-->
        <!--          [outlined]="false"-->
        <!--          label="Запази в таблица"-->
        <!--          icon="pi pi-file-excel"-->
        <!--          (click)="saveAs('download')"-->
        <!--          size="small">-->
        <!--        </p-button>-->
      </section>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="firstName">
          <div class="flex align-items-center">
            БОП кооператор
            <p-sortIcon field="firstName"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="email" style="min-width:15rem">
          <div class="flex align-items-center">
            Информация
            <p-sortIcon field="email"></p-sortIcon>
          </div>
        </th>

        <th style="min-width:20rem">
          <div class="flex align-items-center">
            Произвеждам и продавам български
          </div>
        </th>


        <th style="min-width:20rem">
          <div class="flex align-items-center">
            Търся да купя/заменя
          </div>
        </th>

        <th style="min-width:20rem">
          <div class="flex align-items-center">
            Предлагам за размяна/бартер
          </div>
        </th>

        <th style="min-width:20rem">
          <div class="flex align-items-center">
            Искам БОП да може
          </div>
        </th>
        <th style="min-width:20rem">
          <div class="flex align-items-center">
            Дарове
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-trustee>
      <tr [pSelectableRow]="trustee">
        <td>
          {{ trustee.firstName }} {{ trustee.lastName }}
        </td>
        <td>
          @if (trustee.phoneNumbers[0]?.number) {
            <div>
              <i class="pi pi-phone"></i> {{ trustee.phoneNumbers[0]?.number }}
            </div>
          }

          @if (trustee.email) {
            <div>
              <i class="pi pi-envelope" [style]="{color: 'green'}"></i> {{ trustee.email }}
            </div>
          }

          @if (trustee.locations[0]?.settlement?.name) {
            <div>
              <i class="pi pi-map" [style]="{color: 'red'}"></i> {{ trustee.locations[0]?.settlement?.name || '' }}
            </div>
          }

        </td>
        <td>
          @for (wish of getWishesArray(trustee.wantToSell); track wish) {
            <div>
              <i class="pi pi-star-fill" [style]="{color: 'yellow'}"></i> {{ wish }}
            </div>
          }
        </td>
        <td>
          @for (wish of getWishesArray(trustee.wantToBuy); track wish) {
            <div>
              <i class="pi pi-star-fill" [style]="{color: 'yellow'}"></i> {{ wish }}
            </div>
          }
        </td>
        <td>
          @for (wish of getWishesArray(trustee.wantToExchange); track wish) {
            <div>
              <i class="pi pi-star-fill" [style]="{color: 'yellow'}"></i> {{ wish }}
            </div>
          }
        </td>
        <td>
          @for (wish of getWishesArray(trustee.bopSoftware); track wish) {
            <div>
              <i class="pi pi-star-fill" [style]="{color: 'yellow'}"></i> {{ wish }}
            </div>
          }
        </td>
        <td>
          @for (wish of getDonationInfos(trustee.givenDonations); track wish) {
            <div>
              <i class="pi pi-star-fill" [style]="{color: 'yellow'}"></i> {{ wish }}
            </div>
          }
        </td>
      </tr>
    </ng-template>
  </p-table>
  <bop-spinner-overlay [loading]="loading">
    Зареждане
  </bop-spinner-overlay>
</section>
