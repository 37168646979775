import {Injectable} from '@angular/core';
import {catchError, map, Observable, of, switchMap, take, tap, throwError} from 'rxjs';
import {PageableResponse} from "../../models/pageable/pageable.response";
import {CreatableDropdownService} from "./creatable-dropdown.service";
import {Store} from "@ngrx/store";

import {MarketState} from "../../../private/market/store/market.state";
import {CategoryResponse} from "../../models/category.response";
import {CategoryRequest} from "../../models/category.request";
import {
  selectNewProductSubcategories,
} from "../../../private/market/store/market.reducer";
import {
  SelectNewCategoriesTagParentComponent
} from "../../components/select-categories-tags/select-new-categories-tag-parent/select-new-categories-tag-parent.component";
import {getAllCategories} from "../../../private/market/store/market.actions";
import {CategoriesService} from "../categories.service";
import {DialogService} from "primeng/dynamicdialog";

@Injectable({providedIn: 'root'})
export class ProductTypeSelectionService implements CreatableDropdownService<CategoryResponse, CategoryRequest> {
  constructor(private store: Store<MarketState>, private categoriesService: CategoriesService, private dialogService: DialogService) {
  }

  newProductSubcategories$ = this.store.select(selectNewProductSubcategories);

  public getAll(): Observable<PageableResponse<CategoryResponse>> {
    return this.newProductSubcategories$.pipe(map(categories => {
      let childrenMatrix: CategoryResponse[][] = [];

      console.log('categories for product types', categories);

      if (categories) {
        categories.forEach(c => childrenMatrix.push(c.children));
      }

      const children = childrenMatrix.flatMap(c => c);

      const page: PageableResponse<CategoryResponse> = PageableResponse.empty();
      page.content = children;

      return page;
    }))
  }

  public create(request: CategoryRequest): Observable<CategoryResponse> {
    return this.newProductSubcategories$.pipe(
      take(1),
      map(categories => {
        const newCategories = categories.slice();
        return this.dialogService.open(SelectNewCategoriesTagParentComponent, {
          header: `Моля, изберете родителска категория за "${request.name}"`,
          data: {
            possibleParents: newCategories
          }
        });
      }),
      switchMap(dialogRef =>
        dialogRef.onClose.pipe(
          switchMap((result: string | undefined) => {
            if (!result) {
              throw new Error('No parent category selected');
            }
            request.parentId = result;
            return this.categoriesService.create(request).pipe(
              tap((response: CategoryResponse) => {
                this.store.dispatch(getAllCategories())
              })
            );
          })
        )
      ),
      catchError(err => {
        console.error('Error during category creation:', err);
        return throwError(() => new Error('Category creation failed'));
      })
    );
  }
}
