import {Component, inject} from '@angular/core';
import {MarketMissingRoleDialogComponent} from "../market-missing-role-dialog/market-missing-role-dialog.component";
import {AVAILABLE_ROLES} from "../../profile/profile.config";
import {LayoutStore} from "../../../shared/store/layout/layout.store";
import {selectCurrentUser} from "../../profile/store/profile.reducer";
import {MenuItem, PrimeIcons} from "primeng/api";
import {Store} from "@ngrx/store";
import {ProfileState} from "../../profile/store/profile.state";
import {DialogService} from "primeng/dynamicdialog";
import {Router} from "@angular/router";

@Component({
    selector: 'bop-market-base',
    standalone: true,
    imports: [],
    templateUrl: './market-base.component.html',
    styleUrl: './market-base.component.scss'
})
export class MarketBaseComponent {
    readonly layoutStore = inject(LayoutStore);
    private currentUser$ = this.store.select(selectCurrentUser);
    private isManifacturer = false;
    private isTransporter = false;

    private marketItems: MenuItem[] = [
        {
            icon: PrimeIcons.UPLOAD,
            label: 'Предлагам',
            expanded: true,
            routerLink: ['private', 'market', 'create'],
            command: event => {
                this.checkIsManifacturer();
            },
        },
        {
            icon: PrimeIcons.SHOPPING_CART,
            label: 'Търся',
            expanded: true,
            routerLink: ['private', 'market', 'products']
        },
        {
            icon: PrimeIcons.TRUCK,
            label: 'Моите доставки',
        },
        {
            icon: PrimeIcons.GIFT,
            label: 'Налични дарове',
            routerLink: ['private', 'market', 'products']
        },
    ]

    constructor(private store: Store<ProfileState>,
                private dialogService: DialogService,
                private router: Router) {
    }

    setBaseLayout() {
        this.layoutStore.resetSidebar();
        this.layoutStore.setSidebarPanelMenuItems(this.marketItems);
        this.layoutStore.setSidebarExpanded(true);

        this.currentUser$.subscribe(user => {
            let currentUserRoleIds = user?.userRoles.map(r => r.roleId) || [];

            if (currentUserRoleIds.includes(AVAILABLE_ROLES.Manifacturer)) {
                this.isManifacturer = true;
            }

            if (currentUserRoleIds.includes(AVAILABLE_ROLES.Transporter)) {
                this.isTransporter = true;
            }
        })
    }

    checkIsManifacturer() {
        if (this.isManifacturer) {
            return;
        }

        this.suggestAddRole();
    }

    checkIsTransporter() {
        if (this.isTransporter) {
            return;
        }

        this.suggestAddRole();
    }

    suggestAddRole() {
        this.dialogService.open(MarketMissingRoleDialogComponent, {header: 'Ролята липсва в профила.', width: '70%'})
            .onClose.subscribe(wantsToAddRole => {
            if (!wantsToAddRole) {
                return;
            }

            this.router.navigate(['private', 'profile', 'edit']);
        })
    }

    ngOnDestroy() {
        this.setBaseLayout()
    }
}
