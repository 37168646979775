import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {PageableResponse} from "../../models/pageable/pageable.response";
import {CreatableDropdownService} from "./creatable-dropdown.service";
import {NamedElement} from "../../models/named-element";

@Injectable({providedIn: 'root'})
export class AcceptedCurrencyService implements CreatableDropdownService<NamedElement, NamedElement> {

  public exchangeCurrencies: NamedElement[] = [
    {id: 1, name: 'ЛЕВ'},
    {id: 2, name: 'ЧАС'},
    {id: 3, name: 'ГРОШ'},
    {id: 4, name: 'БУНАР'},
    {id: 5, name: 'ВСН'},
    {id: 6, name: 'CHF'},
    {id: 7, name: 'CNY'},
    {id: 8, name: 'EUR'},
    {id: 9, name: 'HUF'},
    {id: 10, name: 'RUB'},
    {id: 11, name: 'USD'},
  ];

  public getAll(): Observable<PageableResponse<NamedElement>> {
    const response: PageableResponse<NamedElement> = PageableResponse.empty();
    response.content = this.exchangeCurrencies;
    return of(response);
  }

  public create(request: NamedElement): Observable<NamedElement> {
    return of(new NamedElement())
  }
}
