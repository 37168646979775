<form [formGroup]="productForm">
  <h1 class="text-2xl">Данни за нов продукт</h1>

  <p-accordion [multiple]="true" [activeIndex]="[0]">
    <p-accordionTab header="Основни данни">
      <section class="flex flex-column gap-3">
        <bop-select-categories-tags
          (onCategoryTypeSelection)="addNewSubcategories($event)"
          (onProductTypeSelection)="addNewProductTypes($event)"
          (onTagSelection)="addNewTags($event)"
        ></bop-select-categories-tags>

        <bop-form-row-wrapper>
          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-list-check"></i>
            </p-inputGroupAddon>
            <input formControlName="name" pInputText placeholder="Име"/>
          </p-inputGroup>

          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-list-check"></i>
            </p-inputGroupAddon>
            <input formControlName="brand" pInputText placeholder="Марка"/>
          </p-inputGroup>

        </bop-form-row-wrapper>

        <bop-form-row-wrapper>
          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-objects-column"></i>
            </p-inputGroupAddon>
            <bop-creatable-dropdown-single [style]="{width: '100%'}"
                                           placeholder="Качество"
                                           [canFilter]="false"
                                           [canCreateNew]="false"
                                           [service]="qualityService"
                                           (onSelect)="selectQuality($event)"></bop-creatable-dropdown-single>
          </p-inputGroup>

          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-map"></i>
            </p-inputGroupAddon>
            <bop-creatable-dropdown-single [style]="{width: '100%'}"
                                           placeholder="Произход"
                                           [canFilter]="false"
                                           [service]="originService"
                                           (onSelect)="selectOrigin($event)">
            </bop-creatable-dropdown-single>
          </p-inputGroup>

        </bop-form-row-wrapper>

        <bop-form-row-wrapper>
          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-box"></i>
            </p-inputGroupAddon>
            <bop-creatable-dropdown-single [style]="{width: '100%'}"
                                           placeholder="Мярка"
                                           [service]="measurementService"
                                           (onSelect)="selectMeasurement($event)">
            </bop-creatable-dropdown-single>
          </p-inputGroup>

          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-money-bill"></i>
            </p-inputGroupAddon>
            <bop-creatable-dropdown-multiple [style]="{width: '100%'}" placeholder="Приемани валути"
                                             [service]="acceptedCurrencyService"
                                             (onSelect)="selectCurrency($event)"></bop-creatable-dropdown-multiple>
          </p-inputGroup>
        </bop-form-row-wrapper>

        <bop-form-row-wrapper>
          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-wave-pulse"></i>
            </p-inputGroupAddon>
            <input formControlName="vibrationRequirements" pInputText placeholder="Вибрационни изисквания"/>
          </p-inputGroup>

          <p-inputGroup>
            <p-inputGroupAddon>
              <i class="pi pi-pen-to-square"></i>
            </p-inputGroupAddon>
            <input formControlName="otherRequirements" pInputText placeholder="Други изисквания"/>
          </p-inputGroup>
        </bop-form-row-wrapper>

        <bop-form-row-wrapper>
          <p-inputGroup>
            <label>
              <p-checkbox [binary]="true"
                          formControlName="isTradeUnit"
                          inputId="exchange-currency">
              </p-checkbox>
              Разменна валута?
            </label>
          </p-inputGroup>
        </bop-form-row-wrapper>

        <bop-form-row-wrapper>

          <p-inputGroup>
            <label>
              <p-checkbox
                [binary]="true"
                [(ngModel)]="canCall"
                [ngModelOptions]="{standalone: true}"
                inputId="can-call"></p-checkbox>
              Може да се обажда относно продукта?
            </label>
          </p-inputGroup>

        </bop-form-row-wrapper>

        <bop-form-row-wrapper>
          @if (canCall) {
            <p-inputGroup>
              <p-inputGroupAddon>
                <i class="pi pi-clock"></i>
              </p-inputGroupAddon>
              <input [disabled]="!canCall" formControlName="phoneCallAvailability" pInputText
                     placeholder="Удобни дни и часове"/>
            </p-inputGroup>
          }
        </bop-form-row-wrapper>
      </section>
    </p-accordionTab>

    <p-accordionTab header="Състав">
      <bop-rich-editor [model]="ingredientsEditorModel"></bop-rich-editor>
    </p-accordionTab>

    <p-accordionTab header="Технология на производство">
      <bop-rich-editor [model]="technologyEditorModel"></bop-rich-editor>
    </p-accordionTab>

    <p-accordionTab header="С какво продуктът е уникален?">
      <bop-rich-editor [model]="uniquenessEditorModel"></bop-rich-editor>
    </p-accordionTab>

    <p-accordionTab header="Предстоящи мероприятия">
      <bop-rich-editor [model]="futureEventsEditorModel"></bop-rich-editor>
    </p-accordionTab>

    <p-accordionTab header="Приложение на продукта">
      <bop-rich-editor [model]="applicationEditorModel"></bop-rich-editor>
    </p-accordionTab>

    <p-accordionTab header="Снимки на продукта">
      <bop-form-row-wrapper>
        <div>
          <p class="mb-2 font-italic">Снимка 1</p>
          <bop-photo-choice (fileSelectedEmitter)="setProductImage($event, 0)"></bop-photo-choice>
        </div>

        <div>
          <p class="mb-2 font-italic">Снимка 2</p>
          <bop-photo-choice (fileSelectedEmitter)="setProductImage($event, 1)"></bop-photo-choice>
        </div>

        <div>
          <p class="mb-2 font-italic">Снимка 3</p>
          <bop-photo-choice (fileSelectedEmitter)="setProductImage($event, 2)"></bop-photo-choice>
        </div>
      </bop-form-row-wrapper>
    </p-accordionTab>

    <p-accordionTab header="Снимка на производител">
      <bop-photo-choice (fileSelectedEmitter)="setProducerImage($event)"></bop-photo-choice>
    </p-accordionTab>
  </p-accordion>

  <p-button icon="pi pi-save"
            (click)="saveProductDetails()"
            label="Запазване на продукт"></p-button>

</form>

<!--<bop-spinner-overlay [loading]="loading">-->
<!--  Работим, за да изпратим покана на Вашия нов Довереник!-->
<!--</bop-spinner-overlay>-->
