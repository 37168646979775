<bop-form-wrapper>
  <form [formGroup]="trusteeForm" class="flex flex-column gap-3">
    <h1 class="text-2xl">Данни за довереник</h1>
    <p class="font-italic">Моля въведете данните на Вашия нов довереник и изберете ролите му, както следва</p>

    <bop-form-row-wrapper>
      <p-inputGroup>
        <p-inputGroupAddon>
          <i class="pi pi-user"></i>
        </p-inputGroupAddon>
        <input pInputText formControlName="firstName" placeholder="Име или псевдоним"/>
      </p-inputGroup>

      <p-inputGroup>
        <p-inputGroupAddon>
          <i class="pi pi-user"></i>
        </p-inputGroupAddon>
        <input type="text" pInputText formControlName="lastName" placeholder="Фамилия"/>
      </p-inputGroup>
    </bop-form-row-wrapper>

    <bop-form-row-wrapper>
      <p-inputGroup>
        <p-inputGroupAddon>
          <i class="pi pi-send"></i>
        </p-inputGroupAddon>
        <input pInputText formControlName="email" placeholder="Имейл адрес"/>
      </p-inputGroup>

      <p-inputGroup>
        <p-inputGroupAddon>
          <i class="pi pi-phone"></i>
        </p-inputGroupAddon>
        <!--      <input pInputText placeholder="Телефон 1" (focusout)="normalizePhoneControl(getPhoneNumberControl(0))"/>-->
        <input pInputText placeholder="Телефон 1" formControlName="phone" (focusout)="normalizePhoneControl()"/>
      </p-inputGroup>

      <!--    @if (getPhoneNumberControl(0).invalid && getPhoneNumberControl(0).touched) {-->
      <!--      <small class="text-red-600">Моля, въведете валиден телефонен номер</small>-->
      <!--    }-->

    </bop-form-row-wrapper>

    <bop-settlement-selection
      (onSettlementSelected)="setSettlement($event)"
      (onResidentialAreaSelected)="setResidentialArea($event)">
    </bop-settlement-selection>

    <section class="w-full flex flex-column justify-content-start gap-3">
      <p class="font-italic">Роли:</p>
      @for (role of selectedRoles; track role; let i = $index) {
        <p-checkbox
          [ngModelOptions]="{standalone: true}"
          [label]="role.name"
          [value]="role.roleId"
          [binary]="true"
          [(ngModel)]="role.selected"
        ></p-checkbox>
      }

      <div class="flex justify-content-start gap-2">
        <p-checkbox
          [ngModelOptions]="{standalone: true}"
          [binary]="true"
          [(ngModel)]="otherRole"
        ></p-checkbox>
        <input pInputText placeholder="Друго" [ngModelOptions]="{standalone: true}" [(ngModel)]="otherRoleReason"
               (focusin)="otherRole = true">
      </div>
    </section>

    <section class="w-full flex flex-column justify-content-start gap-3">
      <p class="font-italic">Известие за регистрацията да бъде изпратено чрез:</p>
      <p-checkbox
        [ngModelOptions]="{standalone: true}"
        [binary]="true"
        label="Имейл"
        [(ngModel)]="notifyByEmail"
      ></p-checkbox>

      <p-checkbox
        [ngModelOptions]="{standalone: true}"
        [binary]="true"
        label="SMS"
        [(ngModel)]="notifyBySms"
      ></p-checkbox>

      <p-checkbox
        [ngModelOptions]="{standalone: true}"
        [binary]="true"
        label="По-късно (Статус &quot;За покана&quot;)"
        [(ngModel)]="dontNotify"
      ></p-checkbox>
    </section>

    <p-button (click)="saveTrustee()" [disabled]="trusteeForm.invalid" icon="pi pi-save"
              label="Запазване на довереник"></p-button>

  </form>
</bop-form-wrapper>

<bop-spinner-overlay [loading]="loading">
  Работим, за да изпратим покана на Вашия нов Довереник!
</bop-spinner-overlay>
