import {NamedElement} from "./named-element";
import {TagStrippedResponse} from "./tag-stripped.response";
import {ProductMenuElement} from "./product-menu-element";
import {CategoryFlatResponse} from "./category-flat.response";

export class CategoryResponse extends NamedElement implements ProductMenuElement {
  constructor(
    public approved = false,
    public parent: CategoryFlatResponse = new CategoryFlatResponse(),
    public children: CategoryResponse[] = [],
    public tags: TagStrippedResponse[] = []
  ) {
    super();
  }
}
