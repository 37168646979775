import {Component, inject} from '@angular/core';
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {TooltipModule} from "primeng/tooltip";
import {PhotoChoiceComponent} from "../../../../../shared/components/photo-choice/photo-choice.component";
import {ButtonModule} from "primeng/button";
import {
  FormRowWrapperComponent
} from "../../../../../shared/components/form-wrapper/form-row-wrapper/form-row-wrapper.component";
import {NgIf} from "@angular/common";
import {PackagingTypeService} from "../../../../../shared/services/creatable-dropdown-service/packaging-type.service";
import {
  CreatableDropdownSingleComponent
} from "../../../../../shared/components/creatable-dropdown/creatable-dropdown-single/creatable-dropdown-single.component";
import {CreatableDropdownOutput} from "../../../../../shared/components/creatable-dropdown/creatable-dropdown.output";
import {
  CreatableDropdownMultipleComponent
} from "../../../../../shared/components/creatable-dropdown/creatable-dropdown-multiple/creatable-dropdown-multiple.component";
import {FileService} from "../../../../../shared/services/file.service";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'bop-market-create-packaging-form',
  standalone: true,
  imports: [
    InputGroupAddonModule,
    InputGroupModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    TooltipModule,
    PhotoChoiceComponent,
    ButtonModule,
    FormRowWrapperComponent,
    NgIf,
    CreatableDropdownSingleComponent,
    CreatableDropdownMultipleComponent
  ],
  templateUrl: './market-create-packaging-form.component.html',
  styleUrl: './market-create-packaging-form.component.scss'
})
export class MarketCreatePackagingFormComponent {
  public fb = inject(FormBuilder);
  public packagingTypeService = inject(PackagingTypeService);
  public fileService = inject(FileService);
  public dialogRef = inject(DynamicDialogRef<MarketCreatePackagingFormComponent>);

  public packagingForm = this.fb.group({
    packagingTypeId: new FormControl<number>(-1, [Validators.required]),
    productsClass: new FormControl(2),
    quantityPerPackaging: new FormControl(null, [Validators.required]),
    stockAvailable: new FormControl(null, [Validators.required]),
    price: new FormControl(null, [Validators.required, Validators.min(0.01)]),
    packageImageId: new FormControl<number>(-1, [Validators.required]),
  });

  onSubmit() {
    this.dialogRef.close(this.packagingForm.value);
  }

  selectPackagingType($event: CreatableDropdownOutput) {
    this.packagingForm.get('packagingTypeId')?.setValue($event.singleOutput.id);
  }

  selectImage($event: File) {
    this.fileService.uploadFile($event).subscribe(fileResponse => this.packagingForm.get('packageImageId')?.setValue(fileResponse.id));
  }
}
